<!-- <!DOCTYPE html>
<html>
   <head>
      <title>Nauatili</title>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <link rel="icon" href="images/fav.png" type="image/png" sizes="16x16">
      <link href="css/bootstrap.css" rel="stylesheet" />
      <link href="css/aos.css" rel="stylesheet" />
      <link href="css/owl.carousel.css" rel="stylesheet">
      <link href="css/style.css" rel="stylesheet" type="text/css" media="all" />
      <script src="https://kit.fontawesome.com/2b7d98f67f.js"></script>
   </head>
   <body> -->

<jw-modal class="first_modal in" id="up-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('up-modal')">
          <img src=" ../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <div class="col-md-12">
                  <form [formGroup]="eventForm1" #signupform [formGroup]="eventForm1" (ngSubmit)="addSignup()">
                    <div class="form-group file_type">
                      <img [src]="imageSrc" (error)="onImgError($event)" style="border-radius: 50%" />
                      <input placeholder="profilepic" type="file" class="form-control" fullWidth #file accept="image/*"
                        id="image" name="image" (change)="preview($event)" />
                    </div>
                    <!-- <div>
                                       <figure class="image is-128x128"  height="50px" width="50px" style="border-radius: 50%;">
                                         <img [src]="imageSrc"/>
                                       </figure>
                                     </div> -->
                    <div class="form-group">
                      <img src="../../assets/images/user.png" />
                      <input placeholder="Username" type="text" class="form-control" #username id="username"
                        formControlName="username" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.username.errors,
                          'form-control-danger':
                            submitted1 && f1.username.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.username.errors">
                        <p class="error-message">Username is required!</p>
                      </ng-container>
                    </div>
                    <!-- <div class="form-group">
                                                  <img src="images/user.png" > 
                                                             <input placeholder="Username" type="email" class="form-control" id="email1" name="email" required="">
                                                          </div> -->
                    <div class="form-group">
                      <img src="../../assets/images/call.png" />
                      <input placeholder="+1 Phone Number" type="phone" class="form-control" #phone id="phone"
                        formControlName="phone" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.phone.errors,
                          'form-control-danger': submitted1 && f1.phone.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.phone.errors">
                        <p class="error-message">
                          Valid Phone number is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/location.png" />
                      <input placeholder="Location" type="text" class="form-control" #location id="location"
                        formControlName="location" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.location.errors,
                          'form-control-danger':
                            submitted1 && f1.location.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.location.errors">
                        <p class="error-message">Location is required!</p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/email.png" />
                      <input placeholder="Email" type="email" class="form-control" id="email" #email
                        formControlName="email" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.email.errors,
                          'form-control-danger': submitted1 && f1.email.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.email.errors">
                        <p class="error-message">Valid Email is required!</p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input placeholder="Password" type="password" class="form-control" #password id="password"
                        formControlName="password" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.password.errors,
                          'form-control-danger':
                            submitted1 && f1.password.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.password.errors">
                        <p class="error-message">
                          Minimum 6 characters Password is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input placeholder=" Confirm Password" type="password" class="form-control" #confirmpassword
                        id="confirmpassword" formControlName="confirmpassword" required="" [ngClass]="{
                          'is-invalid': submitted1 && f1.confirmpassword.errors,
                          'form-control-danger':
                            submitted1 && f1.confirmpassword.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.confirmpassword.errors">
                        <p class="error-message" *ngIf="
                            submitted1 && f1.confirmpassword.errors.required
                          ">
                          Please enter the confirm password !
                        </p>
                        <p class="error-message" *ngIf="
                            f1.confirmpassword.errors.passwordNotMatch &&
                            f1.confirmpassword.value != ''
                          ">
                          Confirm password is not match with password!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group terms">
                      <label for="other" style="margin-top: 3px"><a href="../../assets/docs/terms_and_conditions.pdf"
                          target="_blank">I accept terms & conditions</a></label>
                      <input type="radio" formControlName="other" id="other" value="other" [ngClass]="{
                          'is-invalid': submitted1 && f1.other.errors,
                          'form-control-danger': submitted1 && f1.other.errors
                        }" />
                      <ng-container *ngIf="submitted1 && f1.other.errors">
                        <p class="error-message">
                          Please accept the terms and conditions !
                        </p>
                      </ng-container>
                    </div>

                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign Up " id="signup" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showMsg">
                      <p class="alert alert-danger">
                        <strong>Signup Failed</strong> User with same Email
                        already exists !
                      </p>
                    </div>
                    <div class="rgister">
                      Already a member?
                      <a href="javascript:void(0)" (click)="openModal('login-modal')">Sign in
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<jw-modal class="first_modal in" id="login-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('login-modal')">
          <img src="../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <img src="../../assets/images/logo.png" class="modal-img" />
                <h3 class="">
                  Sign in to your account <br />
                  to continue!
                </h3>
                <div class="col-md-12">
                  <form [formGroup]="eventForm" #loginform [formGroup]="eventForm" (ngSubmit)="checkLogin()"
                    id="loginform">
                    <div class="form-group">
                      <input placeholder="Username" type="text" class="form-control" #email1 id="email1"
                        formControlName="email1" required="" [ngClass]="{
                          'is-invalid': submitted && f.email1.errors,
                          'form-control-danger': submitted && f.email1.errors
                        }" />
                      <ng-container *ngIf="submitted && f.email1.errors">
                        <p class="error-message loginemail">
                          Email is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input placeholder="Password" type="password" class="form-control" #password1 id="password1"
                        formControlName="password1" required="" [ngClass]="{
                          'is-invalid': submitted && f.password1.errors,
                          'form-control-danger': submitted && f.password1.errors
                        }" />
                      <ng-container *ngIf="submitted && f.password1.errors">
                        <p class="error-message">Password is required!</p>
                      </ng-container>
                    </div>
                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign In " class="signin" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showLoginMsgEmail">
                      <p class="alert alert-danger" style="margin: 0 auto; margin-top: 20px">
                        <strong>Signin Failed</strong> Invalid Username !!
                      </p>
                    </div>
                    <div class="row" *ngIf="showLoginMsgPwd">
                      <p class="alert alert-danger" style="margin: 0 auto; margin-top: 20px">
                        <strong>Signin Failed</strong> Incorrect Password !!
                      </p>
                    </div>
                    <div class="rgister">
                      Don’t have an account?
                      <a href="javascript:void(0)" (click)="openModal('up-modal')">Register Now</a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<div class="" id="loading_image">
  <!-- <img src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/35771931234507.564a1d2403b3a.gif"> -->
  <h4>Please wait. Payment is processing...</h4>
</div>

<header class="" id="navbar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/"><img src="../../assets/images/logo.png" /></a>
          <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navb">
            <span class="navbar-toggler-icon click_class_agian"></span>
          </button>
          <div class="collapse navbar-collapse" id="navb">
            <ul class="navbar-nav ul_second ml-auto mt-3">
              <li class="nav-item profiles" *ngIf="lawPicType != ''">
                <a class="nav-link"><img src="{{ lawPic }}" height="50" width="50" style="border-radius: 50%" /></a>
              </li>
              <li class="nav-item profiles" *ngIf="lawPicType == ''">
                <a class="nav-link"><img src="../../assets/images/userr.png" height="50" width="50"
                    style="border-radius: 50%" /></a>
              </li>
              <li class="nav-item" style="margin-top: 6px">
                <a class="nav-link" (click)="signOut()">Sign Out</a>
              </li>
            </ul>
          </div>
          <div class="mobile_menu mobile_navbar" id="navb_mobile">
            <a class="close_me"> <img src="../../assets/images/close.png" /></a>
            <!-- <form class="form-inline my-2 my-lg-0 srch_bar up">
                           <input class="form-control mr-sm-2" type="text" placeholder="Search...">
                           <button class="btn btn-success my-2 my-sm-0" type="button"><i aria-hidden="true" class="fa fa-search"></i></button>
                        </form> -->
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <ul class="navbar-nav log_in up">
              <!-- <li class="nav-item">
                              <a class="nav-link" (click)="openModal('login-modal')">Sign In</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" (click)="openModal('up-modal')">Sign Up</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link"(click)="signOut()"><img class="log_out" src="../../assets/images/logout.png" > Log Out</a>
                           </li> -->
              <li class="nav-item" *ngIf="lawFirmEmail != null">
                <a class="nav-link" (click)="signOut()"><img class="log_out" src="../../assets/images/logout.png" />
                  Log Out</a>
              </li>
              <li class="nav-item">
                <p class="soci">
                  <a href="https://www.facebook.com/maxc1936" target="_blank">
                    <img src="../../assets/images/f.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f2.png"> </a> -->
                  <a href="https://www.youtube.com/user/0426Max/videos" target="_blank">
                    <img src="../../assets/images/f3.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f4.png"> </a> -->
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>

<section class="stpes">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 mx-auto">
        <div class="third_step">
          <h4>Step 4 of 4</h4>
          <a style="margin-bottom: 15px" class="price_pics" href="/step3/{{ subTypeValue }}">Pay using paypal <img
              src="../../assets/images/cards.png" />
            <i class="fa fa-check-square" style="font-size: 20px; margin-left: 7px"></i></a>

          <!-- <div #paypal></div> -->
          <div id="paypal-button-container"></div>
          <!-- <form [formGroup]="eventForm" #form [formGroup]="eventForm" (ngSubmit)="addEvent()">
                                    <div class="form-group">
                                       <input placeholder="First Name" type="text" class="form-control" id="firstname" name="firstname" required="">
                                    </div>
                                    <div class="form-group">
                                       <input placeholder="Last Name" type="text" class="form-control" id="lastname" name="lastname" required="">
                                    </div>
                                    <div class="form-group">
                                       <input placeholder="Password" type="password" class="form-control" id="password" name="password" required="">
                                    </div>
                                    <div class="form-group">
                                       <input placeholder="Card Number" type="text" class="form-control" id="cardnumber" name="cardnumber" required="">
                                    </div>
                                    <div class="form-group">
                                       <input placeholder="Expiry Date (MM/YY)" type="password" class="form-control" id="password" name="password" required="">
                                    </div>
                                    <div class="form-group">
                                       <input placeholder="Security Code (CVV)" type="password" class="form-control" id="password" name="password" required="">
                                    </div>
                                    <div class="form-group">
                                      <h5> $19.00 <br><span> Monthly Plan</span> </h5>
                                    </div>
									<div class="form-group terms">
                                       <label for="other">I accept  <a href="" > terms &amp; conditions </a></label>
										<input type="radio" name="gender" id="other" value="other">
                                    </div>
                                    <div class="paddin_zero">
                                       <div class="log_button">
                                          <input type="submit" value="Buy ">
                                       </div>
                                    </div>
									
                                 </form> -->
        </div>
      </div>
    </div>
  </div>
</section>

<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="foot-about">
          <h4>Contact Info</h4>
          <p>Nauatili LLC</p>
          <p>Chicago IL 60609</p>
          <p class="mt-3"><b> Email: </b>nauatili2020@gmail.com</p>
          <p class="mt-1"><b> Phone: </b>(800) 738-7961</p>
          <p class="mt-1"><b> Fax: </b>(800) 738-7961</p>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Follow Us</h4>
          <ul class="">
            <li>
              <a href="https://www.facebook.com/maxc1936" target="_blank">
                <img src="../../assets/images/f.png" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/nauatili-llc" target="_blank">
                <img src="../../assets/images/f2.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.linkedin.com" target="_blank"> <img src="../../assets/images/f2.png"> </a> </li> -->
            <li>
              <a href="https://www.youtube.com/user/0426Max/videos" target="_blank">
                <img src="../../assets/images/f3.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.plus.google.com" target="_blank"> <img src="../../assets/images/f4.png"> </a> </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Payment Method</h4>
          <ul class="">
            <li>
              <a href="javascript:void(0)">
                <img src="../../assets/images/paypal.jpg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--row close-->
</footer>
<h5 class="cap">2020 © copyright all right reserved</h5>
<script src="../../assets/js/Jquery.min.js"></script>
<script src="../../assets/js/bootstrap.js"></script>
<script src="../../assets/js/owl.carousel.js"></script>
<script src="../../assets/js/aos.js"></script>
<script src="../../assets/js/custom.js"></script>
<script
  src="https://www.paypal.com/sdk/js?client-id=Afo4KSykwfLTsViTwrivFlr00d-Q0U2NxFjmY7M14q7DCgBBACk1IggJl0wPXusrFQ9TgGRGHRZtLbuQ"></script>
<!-- <script src="https://www.paypalobjects.com/api/checkout.js"></script> -->

<!-- </body>
</html> -->