import {
  Component,
  OnInit,
  ViewChild,
  AfterViewChecked,
  ElementRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { environment } from '../../environments/environment';
import { ModalService } from '../_modal';
import { Http } from '@angular/http';
import { ActivatedRoute, Router } from '@angular/router';
declare var $: any;
declare let paypal: any;
@Component({
  selector: 'app-signupstep4',
  templateUrl: './signupstep4.component.html',
  styleUrls: ['./signupstep4.component.css'],
})
export class Signupstep4Component implements OnInit {
  subTypeValue: number;
  passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
      this.eventForm1 &&
      control.value !== this.eventForm1.controls.password.value
    ) {
      return { passwordNotMatch: true };
    }
    return null;
  }
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  product = {
    price: 777.77,
    description: 'used couch',
  };
  paidFor = false;

  // addScript: boolean = false;
  // finalAmount: number = 1;
  lawPic = '';
  lawPicType = '';
  baseUrl = environment.baseUrl;
  userImagesUrl = environment.userImagesUrl;
  lawfirm: any;
  lawFirmEmail = '';
  lawFirmName = '';
  lawFirmPhone = '';
  lawFirmLocation = '';
  imageSrc: string | ArrayBuffer;
  submitted = false;
  eventForm: FormGroup;
  submitted1 = false;
  showLoginMsgEmail = false;
  showLoginMsgPwd = false;
  eventForm1: FormGroup;
  showMsg = false;
  fd = new FormData();
  selectedFile: File = null;
  @ViewChild('loginform') loginform;
  @ViewChild('signupform') signupform;
  @ViewChild('email1') email1: ElementRef;
  @ViewChild('password1') password1: ElementRef;
  @ViewChild('username') username: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('location') location: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('confirmpassword') confirmpassword: ElementRef;
  // paypalConfig = {
  //   env: 'sandbox',
  //   client: {
  //     sandbox: 'Afo4KSykwfLTsViTwrivFlr00d-Q0U2NxFjmY7M14q7DCgBBACk1IggJl0wPXusrFQ9TgGRGHRZtLbuQ'
  //   },
  //   commit: true,
  //   payment: (data, actions) => {
  //     return actions.payment.create({
  //       payment: {
  //         transactions: [
  //           {amount: {total: this.finalAmount, currency: 'USD'}}
  //         ]
  //       }
  //     });
  //   },
  //   onAuthorize: (data, actions) => {
  //     return actions.payment.execute().then((payment) => {

  //     })
  //   }
  // }
  constructor(
    private formBuilder: FormBuilder,
    public formBuilder1: FormBuilder,
    public modalService: ModalService,
    private router: Router,
    public http: Http,
    private _activatedRoute: ActivatedRoute
  ) {}
  @ViewChild('form') form;
  ngOnInit(): void {
    let subType = 0;
    var paypalAmt = 0;

    this._activatedRoute.queryParams.subscribe(
      (params) => (subType = params.id)
    );

    this.subTypeValue = subType;

    // alert(environment.listTwoPractice)

    if (subType == 1) {
      paypalAmt = environment.listOnePractice;
    } else if (subType == 2) {
      paypalAmt = environment.listTwoPractice;
    } else if (subType == 3) {
      paypalAmt = environment.listThreePractice;
    } else if (subType == 4) {
      paypalAmt = environment.listFourPractice;
    } else {
      paypalAmt = 0;
    }

    // alert(paypalAmt);

    $(document).ready(function ($) {
      $('.one').focus(function () {
        $(this).parent().parent().addClass('round');
      });
    });

    $(document).ready(function () {
      //  $("nav").find("li").on("click", ".close_me", function () {
      $('.close_me').click(function () {
        $('#navb_mobile').addClass('hide_div');
      });
      $('.click_class_agian').click(function () {
        $('#navb_mobile').removeClass('hide_div');
        $('#navb_mobile').addClass('hide_divs');
      });
    });

    // this.eventForm = this.formBuilder.group({
    //   email1: ['',[Validators.required]],
    //   password1: ['',[Validators.required]]
    //  });

    // paypal
    //   .Buttons({
    //     createOrder: (data, actions) => {
    //       return actions.order.create({
    //         purchase_units: [
    //           {
    //             description: this.product.description,
    //             amount: {
    //               currency_code: 'USD',
    //               value: this.product.price
    //             }
    //           }
    //         ]
    //       });
    //     },
    //     onApprove: async (data, actions) => {
    //       const order = await actions.order.capture();
    //       this.paidFor= true;
    //       console.log(order);
    //     },
    //     onError: err => {
    //       console.log(err)
    //     }
    //   })
    //   .render(this.paypalElement.nativeElement)

    if (paypalAmt != 0) {
      paypal
        .Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'paypal',
          },
          createOrder: function (data, actions) {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: paypalAmt,
                  },
                },
              ],
            });
          },
          onApprove: function (data, actions) {
            $('#loading_image').addClass('loader');
            return actions.order.capture().then(async function (details) {
              //alert('Transaction completed by ' + details.payer.name.given_name + '!');
              //actions.order.authorize().then(function(authorization) {

              // Get the authorization id
              // var authorizationID = authorization.purchase_units[0]
              //   .payments.authorizations[0].id
              $('#loading_image').addClass('loader');
              console.log(details, '---------------------');
              var lawphone = localStorage.getItem('lawPhone');
              var purp = localStorage.getItem('purp');
              var s = 1;
              $.ajax({
                url: `https://nauatili.com/execute_payment`,
                method: 'POST',
                data: {
                  details,
                  lawphone,
                  subType: subType,
                },
                beforeSend: function () {
                  $('#loading_image').addClass('loader');
                },
                success: function (response) {
                  if (purp == '0') {
                    window.location.href = '/dashboard';
                  } else {
                    window.location.href = '/index';
                  }
                },
              });
              // Call your server to validate and capture the transaction
              // return fetch(this.baseUrl+'execute_payment', {
              //   method: 'post',
              //   headers: {
              //     'content-type': 'application/json'
              //   },
              //   body: JSON.stringify({
              //     orderID: data.orderID,
              //     authorizationID: authorizationID
              //   })
              // });
            });
            //  });
          },
        })
        .render('#paypal-button-container');
    }

    this.lawFirmEmail = localStorage.getItem('lawEmail');
    this.lawPic = this.userImagesUrl + localStorage.getItem('lawPic');
    if (localStorage.getItem('lawPic') == '/images/') {
      this.lawPicType = '';
    } else {
      this.lawPicType = '1';
    }
    const emailPattern = '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
    this.eventForm = this.formBuilder.group({
      email1: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password1: ['', [Validators.required]],
    });

    this.eventForm1 = this.formBuilder1.group({
      username: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('[0-9 ]{10}')]],
      location: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(emailPattern)]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmpassword: [
        '',
        [Validators.required, this.passwordMatcher.bind(this)],
      ],
      other: ['', [Validators.required]],
    });
    var isLoggedOut = localStorage.getItem('isLoggedOut');
    if (isLoggedOut == 'true') {
      //window.location.replace('/');
      this.router.navigate(['/']);
    }
  }

  // addEvent(){
  //   this.submitted = true;
  //   if (this.eventForm.invalid) {
  //     return;
  //   }
  // }

  get f() {
    return this.eventForm.controls;
  }

  // preview(event) {
  //   this.selectedFile = <File>event.target.files[0];
  //   this.formData.append('image', this.selectedFile, this.selectedFile.name);
  // }

  checkLogin() {
    this.submitted = true;
    if (this.eventForm.invalid) {
      return;
    }
    this.http
      .post(this.baseUrl + 'login', {
        email: this.f.email1.value,
        password: this.f.password1.value,
      })
      .subscribe(
        (response: any) => {
          var lawfirm = JSON.parse(response._body);
          localStorage.setItem('lawfirm', lawfirm);
          localStorage.setItem('lawPic', lawfirm.result.profileImage);
          localStorage.setItem('lawEmail', lawfirm.result.email);
          localStorage.setItem('lawName', lawfirm.result.username);
          localStorage.setItem('lawPhone', lawfirm.result.phone);
          localStorage.setItem('lawLocation', lawfirm.result.location);
          localStorage.setItem('purp', '1');
          localStorage.setItem('isLoggedOut', 'false');
          this.router.navigate(['/index']);
        },
        (error) => {
          var lawFirmErr = JSON.parse(error._body);
          if (lawFirmErr.message == 'Email not exists !!') {
            this.showLoginMsgEmail = true;
            setTimeout(() => {
              this.showLoginMsgEmail = false;
            }, 3000);
          } else if (lawFirmErr.message == 'Invalid Password !!') {
            this.showLoginMsgPwd = true;
            setTimeout(() => {
              this.showLoginMsgPwd = false;
            }, 3000);
          }
        }
      );
  }

  addSignup() {
    this.submitted1 = true;
    if (this.eventForm1.invalid) {
      return;
    }

    this.fd.set('username', this.f1.username.value);
    this.fd.set('phone', this.f1.phone.value);
    this.fd.set('location', this.f1.location.value);
    this.fd.set('email', this.f1.email.value);
    this.fd.set('password', this.f1.password.value);
    //this.formData.append('image',this.fileData);
    //formData['username'] = this.f1.username.value;
    //formData['phone'] = this.f1.phone.value;
    //formData['location'] = this.f1.location.value;
    //formData['email'] = this.f1.email.value;
    //formData['password'] = this.f1.password.value;
    this.http.post(this.baseUrl + 'signup', this.fd).subscribe(
      (response: any) => {
        console.log('ENTER---');
        console.log(this.fd);
        var lawfirm = JSON.parse(response._body);
        if (lawfirm.message == 'Exists') {
          this.showMsg = true;
          setTimeout(() => {
            this.showMsg = false;
          }, 3000);
        } else {
          localStorage.setItem('lawfirm', lawfirm);
          console.log('html lawfirm---', lawfirm.result);
          localStorage.setItem('lawPic', lawfirm.result.profileImage);
          localStorage.setItem('lawEmail', lawfirm.result.email);
          localStorage.setItem('lawName', lawfirm.result.username);
          localStorage.setItem('lawPhone', lawfirm.result.phone);
          localStorage.setItem('lawLocation', lawfirm.result.location);
          localStorage.setItem('purp', '0');
          localStorage.setItem('isLoggedOut', 'false');
          this.router.navigate(['/step2']);
        }
      },
      (error) => {}
    );
  }

  preview(event) {
    this.selectedFile = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => (this.imageSrc = reader.result);
    reader.readAsDataURL(this.selectedFile);
    this.fd.append('image', this.selectedFile, this.selectedFile.name);
  }

  onImgError(event) {
    event.target.src = '../../assets/images/up.png';
  }

  openModal(id: string) {
    if (id == 'login-modal') {
      this.email1.nativeElement.value = '';
      this.password1.nativeElement.value = '';
      this.submitted = false;
      this.modalService.close('up-modal');
    } else {
      this.imageSrc = '';
      this.username.nativeElement.value = '';
      this.phone.nativeElement.value = '';
      this.location.nativeElement.value = '';
      this.email.nativeElement.value = '';
      this.password.nativeElement.value = '';
      this.confirmpassword.nativeElement.value = '';
      this.submitted1 = false;
      this.modalService.close('login-modal');
    }
    this.modalService.open(id);
    localStorage.setItem('cnt', '1');
  }

  get f1() {
    return this.eventForm1.controls;
  }

  closeModal(id: string) {
    this.modalService.close(id);
    // if( localStorage.getItem('cnt') == '0' ){
    //   this.modalService.close(id);
    // }
    // localStorage.setItem('cnt','0');
  }

  signOut() {
    this.http.get(this.baseUrl + 'logout').subscribe(
      (response: any) => {
        if (confirm('Are you sure want to logout ? ')) {
          console.log(response);
          // localStorage.removeItem('lawfirm');
          // localStorage.removeItem('lawPic');
          // localStorage.removeItem('purp');
          // localStorage.setItem('isLoggedOut', 'true');
          localStorage.removeItem('lawfirm');
          localStorage.removeItem('lawPic');
          localStorage.removeItem('purp');
          localStorage.removeItem('lawEmail');
          localStorage.removeItem('lawName');
          localStorage.removeItem('lawPhone');
          localStorage.removeItem('lawLocation');
          localStorage.setItem('isLoggedOut', 'true');
          this.router.navigate(['/']);
        }
      },
      (error) => {}
    );
  }

  // ngAfterViewChecked(): void{
  //   if( !this.addScript ){
  //     this.addPaypalScript().then(() => {
  //       paypal.Button.render(this.paypalConfig, '#paypal-checkout-btn')
  //     })
  //   }
  // }

  // addPaypalScript(){
  //   this.addScript = true;
  //   return new Promise((resolve, reject) => {
  //     let scripttagElement = document.createElement('script');
  //     scripttagElement.src = 'https://www.payloadobjects.com/api/checkout.js';
  //     scripttagElement.onload = resolve;
  //     document.body.appendChild(scripttagElement);
  //   })
  // }
}
