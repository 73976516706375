import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import {ModalService} from '../_modal';
import {Injectable} from '@angular/core';
import {Http, Headers} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

//import { WindowRefService, ICustomWindow } from '../WindowRef.service';

declare var $ : any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
@ViewChild('form')
export class HomeComponent implements OnInit {
  //public _window: ICustomWindow;
  baseUrl = environment.baseUrl;
    userImagesUrl = environment.userImagesUrl;
  constructor(public elementRef: ElementRef,public modalService: ModalService,public formBuilder: FormBuilder, public formBuilder2: FormBuilder,public formBuilder1: FormBuilder,private http: Http, private router: Router ) {
    //this._window = windowRef.nativeWindow;
   }
   nrSelect = 0
   nrSelect2=0
  eventForm: FormGroup;
  //isLogin = "";
  eventForm1: FormGroup;
  eventForm2: FormGroup;
  showMsg = false;
  radioStatus = false;
  imageSrc:string | ArrayBuffer;
  fileData: File = null;
  showLoginMsgEmail = false;
  showLoginMsgPwd = false;
  fd = new FormData();
  submitted = false;
  term:string;
  emailSent = 'false';
  message: string;
  lawPic:any = "";
  lawfirm : any;
  lawFirmEmail = '';
  lawPicType = '';
  lawFirmName = '';
  lawFirmPhone = '';
  errmsg:any;
  lawFirmLocation = '';
  imagePath;
  //formData = new FormData();
  selectedFile = null;


  submitted1 = false;
  submitted2 = false;
  imgURL: any;
  cnt = 0;
  @ViewChild('loginform') loginform;
  @ViewChild('signupform') signupform;
  @ViewChild('requestForm') requestForm;
  @ViewChild('requestEmail') requestEmail: ElementRef;
  @ViewChild('requestSubject') requestSubject: ElementRef;
  @ViewChild('requestComment') requestComment: ElementRef;
  @ViewChild('email1') email1: ElementRef;
  @ViewChild('password1') password1: ElementRef;
  @ViewChild('username') username: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('location') location: ElementRef;
  @ViewChild('title') title: ElementRef;
  @ViewChild('language') language: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('confirmpassword') confirmpassword: ElementRef;
   passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        this.eventForm1 &&
        (control.value !== this.eventForm1.controls.password.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
}

signOut(){
  this.http.get(this.baseUrl+'logout').subscribe(
    (response: any) => {
      if(confirm("Are you sure want to logout ? ")){
        localStorage.removeItem('lawfirm');
        localStorage.removeItem('lawPic');
        localStorage.removeItem('purp');
        localStorage.removeItem('lawEmail');
        localStorage.removeItem('lawName');
        localStorage.removeItem('lawPhone');
        localStorage.removeItem('lawLocation');
        localStorage.setItem('isLoggedOut', 'true');
        //localStorage.setItem('isLogin', 'false');
        //this.router.navigate(['/']);
        window.location.reload();
      } 
    },
    (error) => {

    } 
  );
}

  checkLogin(){
    this.submitted = true;
    if (this.eventForm.invalid) {
      return;
    }
    this.http.post(this.baseUrl+'login', {email: this.f.email1.value, password: this.f.password1.value}).subscribe(
      (response: any) => {
        var lawfirm = JSON.parse(response._body);
        localStorage.setItem('lawfirm', lawfirm);
        localStorage.setItem('lawPic', lawfirm.result.profileImage)
        localStorage.setItem('lawEmail', lawfirm.result.email)
        localStorage.setItem('lawName', lawfirm.result.username)
        localStorage.setItem('lawPhone', lawfirm.result.phone)
        localStorage.setItem('lawLocation', lawfirm.result.location)
        localStorage.setItem('purp', '1');
        localStorage.setItem('isLoggedOut', 'false');
        //localStorage.setItem('isLogin', 'true');
        this.router.navigate(['/index']); 
      },
      (error) => {
        var lawFirmErr = JSON.parse(error._body);
        if( lawFirmErr.message == "Email not exists !!" ){
          this.showLoginMsgEmail = true;
          setTimeout(() => {
            this.showLoginMsgEmail = false;
          },3000)
        } else if( lawFirmErr.message == "Invalid Password !!" ){
          this.showLoginMsgPwd = true;
          setTimeout(() => {
            this.showLoginMsgPwd = false;
          },3000)
        }
      }
    )
  }

  // preview(event) {
  //   this.selectedFile = event.target.files[0];
  //   console.log(this.selectedFile);
  // }
  preview(event) {
    
    this.selectedFile = <File>event.target.files[0];
    const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(this.selectedFile);
    this.fd.set('image', this.selectedFile, this.selectedFile.name);
  }

  addRequest(){
    this.submitted2 = true;
    if (this.eventForm2.invalid) {
      return;
    }
    var formData = new FormData();
    formData.append('email', this.f2.requestEmail.value);
    formData.append('subject', this.f2.requestSubject.value);
    formData.append('message', this.f2.requestComment.value);
    this.http.post(this.baseUrl+'addRequest', formData).subscribe(
      (response: any) => {
        console.log(response);
        this.requestEmail.nativeElement.value = '';
        this.requestSubject.nativeElement.value = '';
        this.requestComment.nativeElement.value = '';
        this.emailSent = "true";
        setTimeout(() => {
          this.emailSent = "false";
        },3000)
      },
      (error) => {

      } 
    )
  }

  // addSignup(event){
  //   this.submitted1 = true;
  //   if (this.eventForm1.invalid) {
  //     return;
  //   }
  //   this.fd.append('username',this.f1.username.value);
  //   this.fd.append('phone',this.f1.phone.value);
  //   this.fd.append('location',this.f1.location.value);
  //   this.fd.append('email',this.f1.email.value);
  //   this.fd.append('password',this.f1.password.value);
  //   this.fd.append('image', this.selectedFile, this.selectedFile.name);
  //   //this.formData.append('image',this.fileData);
  //   //formData['username'] = this.f1.username.value;
  //   //formData['phone'] = this.f1.phone.value;
  //   //formData['location'] = this.f1.location.value;
  //   //formData['email'] = this.f1.email.value;
  //   //formData['password'] = this.f1.password.value;
  //   //const headers = new HttpHeaders().set("Content-Type", "multipart/form-data");
  //   this.http.post(this.baseUrl+'signup', this.fd).subscribe(
  //      (response: any) => {
      //   var lawfirm = JSON.parse(response._body);
      //  localStorage.setItem('lawfirm', lawfirm);
      //  console.log("html lawfirm---",lawfirm.result)
      //  localStorage.setItem('lawPic', lawfirm.result.profileImage)
      //  localStorage.setItem('lawEmail', lawfirm.result.email)
      //  localStorage.setItem('lawName', lawfirm.result.username)
      //  localStorage.setItem('lawPhone', lawfirm.result.phone)
      //  localStorage.setItem('lawLocation', lawfirm.result.location)
      //  localStorage.setItem('purp', '0');
      //  localStorage.setItem('isLoggedOut', 'false');
      //   this.router.navigate(['/step2']); 
  //     },
  //     (error) => {
  //       this.showMsg = true;
  //       setTimeout(() => {
  //         this.showMsg = false;
  //       },3000)
  //     }
  //   )
  // }

  addSignup(){
    this.submitted1 = true;
    if (this.eventForm1.invalid) {
      return;
    }
    
    this.fd.set('username',this.f1.username.value);
    this.fd.set('phone',this.f1.phone.value);
    this.fd.set('location',this.f1.location.value);
    this.fd.set('email',this.f1.email.value);
    this.fd.set('password',this.f1.password.value);
    this.fd.set('lawTitle',this.f1.title.value);
    this.fd.set('language',this.f1.language.value);

    //this.formData.append('image',this.fileData);
    //formData['username'] = this.f1.username.value;
    //formData['phone'] = this.f1.phone.value;
    //formData['location'] = this.f1.location.value;
    //formData['email'] = this.f1.email.value;
    //formData['password'] = this.f1.password.value;
    this.http.post(this.baseUrl+'signup', this.fd).subscribe(
      (response: any) => {
        console.log("ENTER---")
        console.log(this.fd);
        var lawfirm = JSON.parse(response._body);
        if( lawfirm.message == "Exists" ){ 
          this.showMsg = true;
        setTimeout(() => {
          this.showMsg = false;
        },3000)
        } else{
          localStorage.setItem('lawfirm', lawfirm);
        console.log("html lawfirm---",lawfirm.result)
        localStorage.setItem('lawPic', lawfirm.result.profileImage)
        localStorage.setItem('lawEmail', lawfirm.result.email)
        localStorage.setItem('lawName', lawfirm.result.username)
        localStorage.setItem('lawPhone', lawfirm.result.phone)
        localStorage.setItem('lawLocation', lawfirm.result.location)
        localStorage.setItem('purp', '0');
        //localStorage.setItem('isLogin', 'true');
        localStorage.setItem('isLoggedOut', 'false');
         this.router.navigate(['/step2']);  
        } 
      },
      (error) => {
        // console.log(error);
        if(error.status == 400){
          this.showMsg = true;
          let errObj = JSON.parse(error._body);
          this.errmsg = errObj.message
          // console.log('------i am here');          
          // console.log(error.error.message);
        }
        
      }
    )
  }


  get f() { return this.eventForm.controls; }
  get f1() { return this.eventForm1.controls; }
  get f2() { return this.eventForm2.controls; }
  ngOnInit(){
        $(document).ready(function() {
          $(".one").focus(function(){
          $(this).parent().parent().addClass("round");
          })
          
          }); 

          $(document).ready(function () {
    //  $("nav").find("li").on("click", ".close_me", function () {
      $(".close_me").click(function () {
          $("#navb_mobile").addClass("hide_div");

    });
    $(".click_class_agian").click(function () {
      $("#navb_mobile").removeClass("hide_div");
          $("#navb_mobile").addClass("hide_divs");

    });

    // $("#signup").click(function(){
    //   console.log($('.loginemail').innerHTML)
      
    //   // $("#email1").val("");
    //   // $("#password1").val("");
    // })

    // ------------------

  //   $('#srchBtn').bind('click', function (e) {    
  //     e.preventDefault();    
  //     //$('body').find('.highlight').removeClass('highlight')
  //     var searchTxtBox = $('#searchText');    
  //     searchTxtBox.val(searchTxtBox.val().replace(/(\s+)/,"(<[^>]+>)*$1(<[^>]+>)*"));    
  //     var textarea = $('body');    
  //     var enew = '';    
  //     if (searchTxtBox.val() != '') {    

  //         enew = textarea.html().replace(/(<mark>|<\/mark>)/igm, "");    
  //         textarea.html(enew);     
  //         var newtext = '';
  //         var query = new RegExp("("+searchTxtBox.val()+")", "gim");    
  //         newtext= textarea.html().replace(query, "<mark>$1</mark>");    
  //         newtext= newtext.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,"</mark><mark>");    

  //         textarea.html(newtext);     

  //     }    
  //     else {    
  //         enew = textarea.html().replace(/(<mark>|<\/mark>)/igm, "");    
  //         textarea.html(enew);     
  //     }    
  // });  

  //-------------------
    });
      // $(document).ready(function(){
      //   $('#loginmodal').click(function() {
      //     this.modalService.open('login-modal');
      //   });
      // })

      const emailPattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
      this.eventForm = this.formBuilder.group({
        email1: ['',[Validators.required,Validators.pattern(emailPattern)]],
        password1: ['',[Validators.required]]
       });


       this.eventForm1 = this.formBuilder1.group({
        username: ['',[Validators.required]],
        phone: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],
        location: ['',[Validators.required]],
        title: ['',[Validators.required]],
        language: ['',[Validators.required]],
        email: ['',[Validators.required,Validators.pattern(emailPattern)]],
        password: ['',[Validators.required, Validators.minLength(6)]],
        confirmpassword: ['',[Validators.required, this.passwordMatcher.bind(this)]],
        other: ['',[Validators.required]]
       });

       
       this.eventForm2 = this.formBuilder2.group({
        requestEmail: ['',[Validators.required,Validators.pattern(emailPattern)]],
        requestSubject: ['',[Validators.required]],
        requestComment: ['',[Validators.required]]
       });
       this.lawPic = this.userImagesUrl + localStorage.getItem('lawPic');
    this.lawFirmEmail = localStorage.getItem('lawEmail');
    this.lawFirmName = localStorage.getItem('lawName');
    this.lawFirmPhone = localStorage.getItem('lawPhone');
    this.lawFirmLocation = localStorage.getItem('lawLocation');
    console.log(this.lawFirmEmail)
    // this.isLogin = localStorage.getItem('isLogin');
    // console.log("ISlogin----", this.isLogin);
    if( localStorage.getItem('lawPic') == "/images/" ){
      this.lawPicType = "";
    } else {
      this.lawPicType = "1";
    }
       var isLoggedOut = localStorage.getItem('isLoggedOut');
       if( isLoggedOut == 'true' ){
        //window.location.replace('/');
        this.router.navigate(['/']);
      } 

  }


  openModal(id: string) {
    if(id == 'login-modal'){
      this.email1.nativeElement.value = '';
      this.password1.nativeElement.value = '';
      this.submitted = false;
      this.modalService.close('up-modal');
    } else {
      this.imageSrc = '';
      this.username.nativeElement.value = '';
      this.phone.nativeElement.value = '';
      this.location.nativeElement.value = '';
      this.email.nativeElement.value = '';
      this.password.nativeElement.value = '';
      this.confirmpassword.nativeElement.value = '';
      this.submitted1 = false;
      this.modalService.close('login-modal');
    }
    this.modalService.open(id);
    localStorage.setItem('cnt','1');
}

closeModal(id: string) {
  this.modalService.close(id);
  // if( localStorage.getItem('cnt') == '0' ){
  //   this.modalService.close(id);
  // }
  // localStorage.setItem('cnt','0');
}

findText(){

  // var text = $('#searchText').val();
  //   var query = new RegExp("(\\b" + text + "\\b)", "gim");
  //   var e = $('body').innerHTML;
  //   var enew = e.replace(/(<span>|<\/span>)/igm, "");
  //   document.getElementById("body").innerHTML = enew;
  //   var newe = enew.replace(query, "<span>$1</span>");
  //   document.getElementById("body").innerHTML = newe;

  //---------------
  var searchTxtBox = $('#searchText');
  console.log(searchTxtBox.val())    
      searchTxtBox.val(searchTxtBox.val().replace(/(\s+)/,"(<[^>]+>)*$1(<[^>]+>)*"));    
      var textarea = $('body');    
      var enew = '';    
      if (searchTxtBox.val() != '') {    

          enew = textarea.html().replace(/(<mark>|<\/mark>)/igm, "");    
          textarea.html(enew);     
          var query = new RegExp("("+searchTxtBox.val()+")", "gim");    
          var newtext= textarea.html().replace(query, "<mark>$1</mark>");    
          newtext= newtext.replace(/(<mark>[^<>]*)((<[^>]+>)+)([^<>]*<\/mark>)/,"</mark><mark>");    

          textarea.html(newtext);     

      }    
      else {    
          enew = textarea.html().replace(/(<mark>|<\/mark>)/igm, "");    
          textarea.html(enew);     
      } 
    }

  selected = 'Select title';

      titleSelected(e) {
   
      
    if (this.selected == '0') {
      $('.price_tag').html(
        `<s>Attorney</s> $${environment.listZeroTitleString}`
      );
    } else if (this.selected == '1') {
      $('.price_tag').html(
        `<s>Associate</s> $${environment.listOneTitleString}`
      );
    } else if (this.selected == '2') {
      $('.price_tag').html(
        `<s>Legal Assistant</s> $${environment.listTwoTitleString}`
      );
    } else if (this.selected == '3') {
      $('.price_tag').html(
        `<s>Office manager</s> $${environment.listThreeTitleString}`
      );
    } else if (this.selected == '4') {
      $('.price_tag').html(
        `<s>Paralegal</s> $${environment.listFourTitleString}`
      );
    }else {
    }
  }
    selectedlanguage = 'Select title';

      languageSelected(e) {
   
      
    if (this.selected == '0') {
      $('.price_tag').html(
        `<s>English</s> $${environment.English}`
      );
    } else if (this.selected == '1') {
      $('.price_tag').html(
        `<s>Arabic</s> $${environment.Arabic}`
      );
    } else if (this.selected == '2') {
      $('.price_tag').html(
        `<s>Chinese</s> $${environment.Chinese}`
      );
    } else if (this.selected == '3') {
      $('.price_tag').html(
        `<s>French</s> $${environment.French}`
      );
    } else if (this.selected == '4') {
      $('.price_tag').html(
        `<s>German</s> $${environment.German}`
      );
    }else if (this.selected == '5') {
      $('.price_tag').html(
        `<s>Hindi</s> $${environment.Hindi}`
      );
    }else if (this.selected == '6') {
      $('.price_tag').html(
        `<s>Korean</s> $${environment.Korean}`
      );
    }else if (this.selected == '7') {
      $('.price_tag').html(
        `<s>Polish</s> $${environment.Polish}`
      );
    }else if (this.selected == '8') {
      $('.price_tag').html(
        `<s>Russian</s> $${environment.Russian}`
      );
    }else if (this.selected == '9') {
      $('.price_tag').html(
        `<s>Spanish</s> $${environment.Spanish}`
      );
    }else if (this.selected == '10') {
      $('.price_tag').html(
        `<s>Tagalog</s> $${environment.Tagalog}`
      );
    }
    else if (this.selected == '11') {
      $('.price_tag').html(
        `<s>Farsi</s> $${environment.Farsi}`
      );
    }
    else if (this.selected == '12') {
      $('.price_tag').html(
        `<s>Italian</s> $${environment.Italian}`
      );
    }
    else if (this.selected == '13') {
      $('.price_tag').html(
        `<s>Japanese</s> $${environment.Japanese}`
      );
    }
    else if (this.selected == '14') {
      $('.price_tag').html(
        `<s>Portuguese</s> $${environment.Portuguese}`
      );
    } else if (this.selected == '15') {
      $('.price_tag').html(
        `<s>Vietnamese</s> $${environment.Vietnamese}`
      );
    }else {
    }
  }
//   //-----------------
// }
//   $("body").find(".highlight").removeClass("highlight");

//     var searchword = $("#searchText").val();

//     var custfilter = new RegExp(searchword, "ig");
//     var repstr = "<span class='highlight'" + searchword + "</span>";

//     if (searchword != "") {
//         $('body').each(function() {
//             $(this).html($(this).html().replace(custfilter, repstr));
//         })
//     }
  // var srch = $("#searchText").val();
  // $("body").highlight(srch);
  // var srchCnt = (document.body.innerText.match(new RegExp(srch, "g")) || []).length;
  // var i = 0;
  // while( i < srchCnt ){
  //   document.execCommand("hiliteColor", false, "blue");
  //   i++;
  // }
  // if (document.body.innerText.indexOf(srch) > -1) { 
  //   while (document.body.innerText.indexOf(srch) > -1) {
  //       console.log("HHHHH")
  //      document.execCommand("hiliteColor", false, "blue");
  //   } 
  // }
//}
 
//   // var srchFind = document.body.innerText.indexOf(srch) > -1;
//   // if( srchFind ){
    
//   // } else {
//   //   console.log("Not Find!!")
//   // }
// }

onImgError(event)
{
event.target.src = '../../assets/images/up.png';
}
}
