import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { Signupstep2Component } from './signupstep2/signupstep2.component';
import { Signupstep3Component } from './signupstep3/signupstep3.component';
import { Signupstep4Component } from './signupstep4/signupstep4.component';
import { Home2Component } from './home2/home2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PracticesComponent } from './practices/practices.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactusComponent },
  { path: 'step2', component: Signupstep2Component },
  { path: 'step3', component: Signupstep3Component },
  { path: 'step4', component: Signupstep4Component },
  { path: 'index', component: Home2Component },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'practices', component: PracticesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
