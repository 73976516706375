<jw-modal class="first_modal in" id="login-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('login-modal')">
          <img src="../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <img src="../../assets/images/logo.png" class="modal-img" />
                <h3 class="">
                  Sign in to your account <br />
                  to continue!
                </h3>
                <div class="col-md-12">
                  <form
                    [formGroup]="eventForm"
                    #loginform
                    [formGroup]="eventForm"
                    (ngSubmit)="checkLogin()"
                    id="loginform"
                  >
                    <div class="form-group">
                      <input
                        placeholder="Username"
                        type="text"
                        class="form-control"
                        #email1
                        id="email1"
                        formControlName="email1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.email1.errors,
                          'form-control-danger': submitted && f.email1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.email1.errors">
                        <p class="error-message loginemail">
                          Email is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        #password1
                        id="password1"
                        formControlName="password1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.password1.errors,
                          'form-control-danger': submitted && f.password1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.password1.errors">
                        <p class="error-message">Password is required!</p>
                      </ng-container>
                    </div>
                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign In " class="signin" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showLoginMsgEmail">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Invalid Username !!
                      </p>
                    </div>
                    <div class="row" *ngIf="showLoginMsgPwd">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Incorrect Password !!
                      </p>
                    </div>
                    <div class="rgister">
                      Don’t have an account?
                      <a
                        href="javascript:void(0)"
                        (click)="openModal('up-modal')"
                        >Register Now</a
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<jw-modal class="first_modal in" id="up-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('up-modal')">
          <img src=" ../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <div class="col-md-12">
                  <form
                    [formGroup]="eventForm1"
                    #signupform
                    [formGroup]="eventForm1"
                    (ngSubmit)="addSignup()"
                  >
                    <div class="form-group file_type">
                      <img
                        [src]="imageSrc"
                        (error)="onImgError($event)"
                        style="border-radius: 50%"
                      />
                      <input
                        placeholder="profilepic"
                        type="file"
                        class="form-control"
                        fullWidth
                        #file
                        accept="image/*"
                        id="image"
                        name="image"
                        (change)="preview($event)"
                      />
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/user.png" />
                      <input
                        placeholder="Username"
                        type="text"
                        class="form-control"
                        #username
                        id="username"
                        formControlName="username"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.username.errors,
                          'form-control-danger':
                            submitted1 && f1.username.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.username.errors">
                        <p class="error-message">Username is required!</p>
                      </ng-container>
                    </div>
                    <!-- <div class="form-group">
                                              <img src="images/user.png" > 
                                                         <input placeholder="Username" type="email" class="form-control" id="email1" name="email" required="">
                                                      </div> -->
                    <div class="form-group">
                      <img src="../../assets/images/call.png" />
                      <input
                        placeholder="+1 Phone Number"
                        type="phone"
                        class="form-control"
                        #phone
                        id="phone"
                        formControlName="phone"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.phone.errors,
                          'form-control-danger': submitted1 && f1.phone.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.phone.errors">
                        <p class="error-message">
                          Valid Phone number is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/location.png" />
                      <input
                        placeholder="Location"
                        type="text"
                        class="form-control"
                        #location
                        id="location"
                        formControlName="location"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.location.errors,
                          'form-control-danger':
                            submitted1 && f1.location.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.location.errors">
                        <p class="error-message">Location is required!</p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/email.png" />
                      <input
                        placeholder="Email"
                        type="email"
                        class="form-control"
                        id="email"
                        #email
                        formControlName="email"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.email.errors,
                          'form-control-danger': submitted1 && f1.email.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.email.errors">
                        <p class="error-message">Valid Email is required!</p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        #password
                        id="password"
                        formControlName="password"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.password.errors,
                          'form-control-danger':
                            submitted1 && f1.password.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.password.errors">
                        <p class="error-message">
                          Minimum 6 characters Password is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder=" Confirm Password"
                        type="password"
                        class="form-control"
                        #confirmpassword
                        id="confirmpassword"
                        formControlName="confirmpassword"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.confirmpassword.errors,
                          'form-control-danger':
                            submitted1 && f1.confirmpassword.errors
                        }"
                      />
                      <ng-container
                        *ngIf="submitted1 && f1.confirmpassword.errors"
                      >
                        <p
                          class="error-message"
                          *ngIf="
                            submitted1 && f1.confirmpassword.errors.required
                          "
                        >
                          Please enter the confirm password !
                        </p>
                        <p
                          class="error-message"
                          *ngIf="
                            f1.confirmpassword.errors.passwordNotMatch &&
                            f1.confirmpassword.value != ''
                          "
                        >
                          Confirm password is not match with password!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group terms">
                      <label for="other" style="margin-top: 3px"
                        ><a
                          href="../../assets/docs/terms_and_conditions.pdf"
                          target="_blank"
                          >I accept terms & conditions</a
                        ></label
                      >
                      <input
                        type="radio"
                        formControlName="other"
                        id="other"
                        value="other"
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.other.errors,
                          'form-control-danger': submitted1 && f1.other.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.other.errors">
                        <p class="error-message">
                          Please accept the terms and conditions !
                        </p>
                      </ng-container>
                    </div>

                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign Up " id="signup" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showMsg">
                      <p class="alert alert-danger">
                        <strong>Signup Failed</strong> User with same Email
                        already exists !
                      </p>
                    </div>
                    <div class="rgister">
                      Already a member?
                      <a
                        href="javascript:void(0)"
                        (click)="openModal('login-modal')"
                        >Sign in
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>

<header class="" id="navbar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/"
            ><img src="../../assets/images/logo.png"
          /></a>
          <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navb"
          >
            <span class="navbar-toggler-icon click_class_agian"></span>
          </button>
          <div class="collapse navbar-collapse" id="navb">
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <ul class="navbar-nav ul_second ml-auto mt-3">
              <!-- <li class="nav-item profiles">
                                 <a class="nav-link" ><img src="../../assets/images/profile.png" ></a>
                              </li> -->
              <!-- <li class="nav-item">
                                 <a class="nav-link"data-toggle="modal" data-target="#up-modal">Sign Out</a>
                              </li> -->
              <ul class="navbar-nav ul_second up" *ngIf="lawFirmEmail != null">
                <li
                  class="nav-item profiles"
                  *ngIf="lawPicType != ''"
                  style="margin-top: 40px"
                >
                  <a class="nav-link"
                    ><img
                      src="{{ lawPic }}"
                      height="50"
                      width="50"
                      style="border-radius: 50%"
                  /></a>
                </li>
                <li
                  class="nav-item profiles"
                  *ngIf="lawPicType == ''"
                  style="margin-top: 40px"
                >
                  <a class="nav-link"
                    ><img
                      src="../../assets/images/userr.png"
                      height="50"
                      width="50"
                      style="border-radius: 50%"
                  /></a>
                </li>
                <li class="nav-item" style="margin-top: 45px">
                  <a class="nav-link" (click)="signOut()">Sign Out</a>
                </li>
              </ul>
            </ul>
          </div>
          <div class="mobile_menu mobile_navbar" id="navb_mobile">
            <a class="close_me"> <img src="../../assets/images/close.png" /></a>
            <form class="form-inline my-2 my-lg-0 srch_bar up">
              <input
                class="form-control mr-sm-2"
                type="text"
                placeholder="Search..."
              />
              <button class="btn btn-success my-2 my-sm-0" type="button">
                <i aria-hidden="true" class="fa fa-search"></i>
              </button>
            </form>
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <ul class="navbar-nav log_in up">
              <!-- <li class="nav-item">
                                 <a class="nav-link" (click)="openModal('login-modal')">Sign In</a>
                              </li>
                              <li class="nav-item">
                                 <a class="nav-link" (click)="openModal('up-modal')">Sign Up</a>
                              </li> -->
              <li class="nav-item" *ngIf="lawFirmEmail != null">
                <a class="nav-link" (click)="signOut()"
                  ><img class="log_out" src="../../assets/images/logout.png" />
                  Log Out</a
                >
              </li>
              <li class="nav-item">
                <p class="soci">
                  <a href="https://www.facebook.com/maxc1936" target="_blank">
                    <img src="../../assets/images/f.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f2.png"> </a> -->
                  <a
                    href="https://www.youtube.com/user/0426Max/videos"
                    target="_blank"
                  >
                    <img src="../../assets/images/f3.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f4.png"> </a> -->
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<section class="baner">
  <img src="../../assets/images/pri.png" />
</section>

<section class="about_t">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="abt_text" *ngIf="showPolicy == false">
          <h3>Privacy policy</h3>
          <h3>Welcome to Nauatili LLC!</h3>
          <p>
            <strong>Nauatili LLC </strong>values your privacy. This notice
            describes what information we collect, how we use it, and, if
            necessary, those with whom we share it.
          </p>
          <p>
            <strong
              >By using the Nauatili LLC platform and services, you are
              accepting the practices described in this Privacy Policy.</strong
            >
            Your use of the services is also subject to our Terms and
            Conditions.
          </p>
          <p>
            This Privacy Policy may change from time to time. Your continued use
            of the platform after we make changes is deemed to be acceptance of
            those changes, so please check the policy periodically for updates.
            This Privacy Policy has been developed and is maintained in
            accordance with all applicable federal and international laws and
            regulations and specifically with the California Online Privacy
            Protection Act (CalOPPA – U.S regulation).
          </p>
          <button
            class="btn btn-primary"
            style="background-color: #2959a1"
            (click)="policyBtn()"
          >
            Read More
          </button>
        </div>
        <div class="abt_text" *ngIf="showPolicy == true">
          <h3>Privacy policy</h3>
          <h3>Welcome to Nauatili LLC!</h3>
          <p>
            <strong>Nauatili LLC </strong>values your privacy. This notice
            describes what information we collect, how we use it, and, if
            necessary, those with whom we share it.
          </p>
          <p>
            <strong
              >By using the Nauatili LLC platform and services, you are
              accepting the practices described in this Privacy Policy.</strong
            >
            Your use of the services is also subject to our Terms and
            Conditions.
          </p>
          <p>
            This Privacy Policy may change from time to time. Your continued use
            of the platform after we make changes is deemed to be acceptance of
            those changes, so please check the policy periodically for updates.
            This Privacy Policy has been developed and is maintained in
            accordance with all applicable federal and international laws and
            regulations and specifically with the California Online Privacy
            Protection Act (CalOPPA – U.S regulation).
          </p>
          <h3>GENERAL INFORMATION</h3>
          <p>
            The personal data of the users that are collected and processed
            through:
            <strong
              >“Nauatili LLC” Application (Android and iOS version – Available
              on Google Play and App store)</strong
            >
            Will be under responsibility and in charge of:
          </p>
          <p>
            <strong
              >Nauatili LLC (Insert contact information, email, phone, etc.)
              (Hereinafter referred to as “Nauatili LLC”).
            </strong>
          </p>
          <h3>TYPES OF INFORMATION GATHERED</h3>
          <p>
            The information we learn from customers helps personalize and
            continually improve your experience at Nauatili LLC. Here are the
            types of information we gather:
          </p>
          <p>
            <strong>Information You Give Us.</strong> You provide information
            when you search, read and watch the content, register as a user, buy
            the subscriptions or services or/and communicate with us. As a
            result of those actions, you might supply us with the following
            information:
          </p>
          <ol>
            <li>First and last name</li>
            <li>Email address</li>
            <li>Phone number (includes WhatsApp)</li>
            <li>Company information (law firms)</li>
            <li>Law firm license information</li>
            <li>Profile picture</li>
            <li>Address</li>
            <li>Zip/Postal code</li>
            <li>Payment information</li>
            <li>IP address</li>
            <li>
              Any additional information relating to you that you provide to us
            </li>
          </ol>
          <p>
            directly through our platforms or indirectly through our platforms
            or online presence such as ‘cookies’. Nauatili LLC will not collect
            any personally identifiable information about you, unless you
            provide it.
          </p>

          <p>
            <strong>Payment information:</strong> Your credit card or payment
            data will be processed by "PayPal" (payment platform), which will
            treat and safeguard your data in total security and for the sole
            purpose of processing the purchase of subscriptions. Nauatili LLC
            reserves the right to contract any payment platform available in the
            market, which treats your data with the only purpose of processing
            the purchase of subscriptions. Please consult “PayPal” Privacy
            Policy here: https://www.paypal.com/us/webapps/mpp/ua/privacy-full
          </p>
          <p>
            <strong>Automatic Information. </strong>Like many platforms, we use
            "cookies." We obtain certain types of anonymous information which is
            not personally identifiable when your web browser accesses Nauatili
            LLC or third-party content served by or on behalf of Nauatili LLC on
            other platforms. Some of the information we collect and analyze
            includes the Internet protocol (IP) address; computer and connection
            information such as browser type, version, and connection speed;
            purchase history; and the content you searched for, viewed, and
            possibly purchased.
          </p>
          <h3>HOW LONG WE KEEP YOUR DATA</h3>
          <p>
            The owner may be allowed to retain Personal Data for a longer period
            whenever the user has given consent to such processing, as long as
            such consent is not withdrawn. Furthermore, the Owner may be obliged
            to retain Personal Data for a longer period whenever required to do
            so for the performance of a legal obligation or upon order of an
            authority. Once the retention period expires, Personal Data shall be
            deleted. Therefore, the right to access, the right to erasure, the
            right to rectification and the right to data portability cannot be
            enforced after expiration of the retention period.
          </p>
          <h3>HOW WE USE YOUR INFORMATION.</h3>
          <p>
            In general, we use the information we collect primarily to provide,
            maintain, protect and improve our current platform and services. We
            use personal information collected through our site as described
            below and described elsewhere in this Policy to:
          </p>
          <ol>
            <li>Identify you as a user in our system.</li>
            <li>User registration.</li>
            <li>Publish user content.</li>
            <li>Provide the subscriptions.</li>
            <li>Process payments</li>
            <li>Provide search results through the search engine.</li>
            <li>
              Improve our services, platform, and how we operate our business.
            </li>
            <li>
              Understand and enhance your experience using our platform and
              services.
            </li>
            <li>
              Send emails through bulk email delivery platforms such as
              MailChimp and Klaviyo.
            </li>
            <li>
              Respond to your comments or questions through our support team
              (Chat and email).
            </li>
            <li>
              Send you related information, including confirmations, invoices,
              technical notices, updates, security alerts and support and
              administrative messages.
            </li>
            <li>
              Communicate with you about upcoming events and news about services
              offered by Nauatili LLC and our selected partners.
            </li>
            <li>Marketing purposes of Nauatili LLC.</li>
            <li>
              Link or combine your information with other information we get
              from third parties to help understand your needs and provide you
              with better service.
            </li>
            <li>
              Protect, investigate and deter against fraudulent, unauthorized or
              illegal activity.
            </li>
          </ol>
          <h3>HOW DO YOU GET MY CONSENT?</h3>
          <p>
            By registering, purchasing a subscription, using the services,
            posting content on the platform and providing us with personal
            information to communicate with you, you agree that we may collect
            and use your information. You can withdraw your consent, contact us
            through the contact information or the contact page.
          </p>
          <h3>HOW WE SHARE INFORMATION</h3>
          <p>
            Information about our customers is an important part of our
            business, and we are not in the business of selling it to others. We
            share customer information only as described below.
          </p>
          <p>
            <strong>Other users:</strong> The data published in the profile may
            be shared with other users for the sole purpose of providing legal
            services searches. Similarly, the data you publish through your
            profile may be displayed and shared in the searches for legal
            services of the services. By registering on the platform, you
            authorize us to display and share your information with users who
            perform searches. Information shared through the platform will be
            treated and collected in accordance with this privacy policy.
          </p>
          <p>
            <strong>Third-Party Service Providers. </strong>We employ other
            companies and individuals to perform functions on our behalf.
            Examples include process payments (PayPal), sending email, analyzing
            data, providing marketing assistance, providing search results and
            links. They have access to personal information needed to perform
            their functions, but may not use it for other purposes.
          </p>
          <p>
            <strong>Business Transfers. </strong>In the event that Nauatili LLC
            creates, merges with, or is acquired by another entity, your
            information will most likely be transferred. Nauatili LLC will email
            you or place a prominent notice on our Website before your
            information becomes subject to another privacy policy.
          </p>
          <p>
            <strong>Protection of Nauatili LLC and others. </strong>We release
            personal information when we believe release is appropriate to
            comply with the law, enforce or apply our Terms and conditions and
            other agreements, or protect the rights, property, or safety of
            Nauatili LLC, our users or others. This includes exchanging
            information with other companies and organizations for fraud
            protection and credit risk reduction.
          </p>
          <p>
            <strong>With Your Consent. </strong>Other than as set out above, you
            will receive notice when personally identifiable information about
            you might go to third parties, and you will have an opportunity to
            choose not to share the information.
          </p>
          <p>
            <strong>Anonymous Information. </strong>Nauatili LLC uses the
            anonymous browsing information collected automatically by our
            servers primarily to help us administer and improve the platform and
            platform. We may also use aggregated anonymous information to
            provide information about the platform to potential business
            partners and other unaffiliated entities. This information is not
            personally identifiable.
          </p>
          <p>
            <strong>Email Address. </strong>The email address that you supply to
            us for purposes of receiving our email communications will never be
            rented or sold to a third party.
          </p>
          <h3>PROTECTING YOUR INFORMATION</h3>
          <p>
            We work to protect the security of your information during
            transmission by using Secure Sockets Layer (SSL) software, which
            encrypts information you input. If transactions are processed on the
            Website, transaction information is transmitted to and from the
            Website in encrypted form using industry-standard SSL connections to
            help protect such information from interception. We restrict
            authorized access to your personal information to those persons who
            have a legitimate purpose to know that information to provide
            products or services to you and those persons you have authorized to
            have access to such information.
          </p>
          <p>
            Nauatili LLC follows generally accepted industry standards to
            protect the personal information submitted to us, both during
            transmission and once Nauatili LLC receives it. No method of
            transmission over the Internet, or method of electronic storage, is
            100% secure. Therefore, while Nauatili LLC strives to use
            commercially acceptable means to protect your personal information,
            we cannot guarantee its absolute security.
          </p>
          <p>
            We will not sell, distribute, or lease your personal information to
            third parties unless we have your permission or are required by law
            to do so.
          </p>
          <h3>YOUR RIGHTS</h3>
          <p>
            You have the following rights in relation to your personal data
            provided to Nauatili LLC:
          </p>
          <ol>
            <li>Access your personal data.</li>
            <li>Request the correction of inaccurate data.</li>
            <li>Request its deletion.</li>
            <li>Request limitation of the processing of your data.</li>
            <li>Oppose the treatment of your data.</li>
            <li>Request your portability.</li>
            <li>Not be subject to automated individual decisions.</li>
          </ol>
          <p>
            The Users can exercise all these rights contacting us through the
            contact information or the contact page.
          </p>
          <h3>CALIFORNIA ONLINE PRIVACY PROTECTION ACT COMPLIANCE (CalOPPA)</h3>
          <p>
            CalOPPA is the first state law in the nation to require commercial
            platforms and online services to post a privacy policy. The law’s
            reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that
            operates platforms collecting Personally Identifiable Information
            from California consumers to post a conspicuous privacy policy on
            its platform stating exactly the information being collected and
            those individuals or companies with whom it is being shared.
          </p>
          <p>
            Because we value your privacy, we have taken the necessary
            precautions to be in compliance with the California Online Privacy
            Protection Act (CalOPPA).
          </p>
          <p>
            According to the California Online Privacy act, you have the
            following rights:
          </p>
          <ol>
            <li>Know what personal data is being collected.</li>
            <li>
              Know whether your personal data is sold or disclosed and to whom.
            </li>
            <li>Access to your personal data.</li>
            <li>Request to delete any personal information about you.</li>
            <li>
              Not be discriminated against for exercising their privacy rights.
            </li>
          </ol>
          <h3>CHILDREN’S ONLINE PRIVACY PROTECTION</h3>
          <p>
            We are in compliance with the requirements of the California Online
            Privacy Protection Act (CalOPPA – U.S regulation) regarding the
            protection of the personal data of minors. We do not collect any
            information from anyone under 13 years of age. If you become aware
            that your child has provided us with personal information, please
            contact us. If we become aware that a child has provided us with
            personal information, we will take steps to delete that information,
            terminate that person's account, and restrict access to that person.
          </p>
          <h3>EDITING AND DELETING INFORMATION</h3>
          <p>
            If you believe that any information, we are holding on you is
            incorrect or incomplete, please write to or email us as soon as
            possible. We will promptly correct any information found to be
            incorrect. You can change, modify, rectify and delete your
            Information at any time, please contact us through the contact
            information.
          </p>
          <p>
            To opt-out of Nauatili LLC email, follow the instructions included
            in the email. Your request should be processed within 48 hours.
          </p>
          <h3>SUPPLIERS AND OTHER THIRD PARTIES</h3>
          <p>
            Except as otherwise expressly included in this Privacy Policy, this
            document addresses only the use and disclosure of information
            Nauatili LLC collects from you. If you disclose your information to
            others, whether other users or suppliers on Nauatili LLC, different
            rules may apply to their use or disclosure of the information you
            disclose to them. Nauatili LLC does not control the privacy policies
            of third parties, and you are subject to the privacy policies of
            those third parties where applicable. Nauatili LLC is not
            responsible for the privacy or security practices of other platforms
            on the Internet, even those linked to or from the Nauatili LLC site.
            Nauatili LLC encourages you to ask questions before you disclose
            your personal information to others.
          </p>
          <h3>CHANGES TO THIS PRIVACY POLICY</h3>
          <p>
            Nauatili LLC will post modifications by date to this Privacy Policy
            to inform you of any changes. Nauatili LLC reserves the right to
            modify this Privacy Policy at any time, please review it frequently.
          </p>
          <h3>ENFORCEMENT</h3>
          <p>
            Nauatili LLC regularly reviews its compliance with this Policy.
            Please feel free to direct any questions or concerns regarding this
            Policy or Nauatili LLC treatment of personal information by
            contacting us.
          </p>
          <p>
            When we receive formal written complaints, it is Nauatili LLC policy
            to contact the complaining user regarding his or her concerns. We
            will cooperate with the appropriate regulatory authorities,
            including local data protection authorities, to resolve any
            complaints regarding the transfer of personal data that cannot be
            resolved between Nauatili LLC and an individual.
          </p>
          <h3>CONTACT US</h3>
          <p>
            If you have questions or concerns about these Privacy Policy and the
            handling and security of your data on this platform, please contact
            us at:
          </p>
          <p><strong>(info@nauatili.com)</strong></p>
          <button
            class="btn btn-primary"
            style="background-color: #2959a1"
            (click)="policyBtn()"
          >
            Read Less
          </button>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about_photo">
          <img src="../../assets/images/abt.png" />
        </div>
      </div>
    </div>
  </div>
</section>

<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="foot-about">
          <h4>Contact Info</h4>
          <p>Nauatili LLC</p>
          <p>Chicago IL 60609</p>
          <p class="mt-3"><b> Email: </b>info@nauatili.com</p>
          <p class="mt-1"><b> Phone: </b>(800) 738-7961</p>
          <p class="mt-1"><b> Fax: </b>(800) 738-7961</p>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Follow Us</h4>
          <ul class="">
            <li>
              <a href="https://www.facebook.com/maxc1936" target="_blank">
                <img src="../../assets/images/f.png" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nauatili-llc"
                target="_blank"
              >
                <img src="../../assets/images/f2.png" />
              </a>
            </li>
            <!-- <a href="#"> <img src="../../assets/images/f2.png"> </a> -->
            <li>
              <a
                href="https://www.youtube.com/user/0426Max/videos"
                target="_blank"
              >
                <img src="../../assets/images/f3.png" />
              </a>
            </li>
            <!-- <a href="#"> <img src="../../assets/images/f4.png"> </a> -->
          </ul>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Payment Method</h4>
          <ul class="">
            <li>
              <a href="javascript:void(0)">
                <img src="../../assets/images/paypal.jpg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!--row close-->
</footer>
<h5 class="cap">2020 © copyright all right reserved</h5>
<script src="../../assets/js/Jquery.min.js"></script>
<script src="../../assets/js/bootstrap.js"></script>
<script src="../../assets/js/owl.carousel.js"></script>
<script src="../../assets/js/aos.js"></script>
<script src="../../assets/js/custom.js"></script>
