<!-- <!DOCTYPE html>
<html>
   <head>
      <title>Nauatili</title>
      <meta charset="utf-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <link rel="icon" href="images/fav.png" type="image/png" sizes="16x16">
      <link href="css/bootstrap.css" rel="stylesheet" />
      <link href="css/aos.css" rel="stylesheet" />
      <link href="css/owl.carousel.css" rel="stylesheet">
      <link href="css/style.css" rel="stylesheet" type="text/css" media="all" />
   </head>
   <body> -->
<style>
  .contactBullets {
    width: 100% !important;
  }
</style>
<jw-modal class="first_modal in" id="login-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('login-modal')">
          <img src="../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <img src="../../assets/images/logo.png" class="modal-img" />
                <h3 class="">
                  Sign in to your account <br />
                  to continue!
                </h3>
                <div class="col-md-12">
                  <form
                    [formGroup]="eventForm"
                    #loginform
                    [formGroup]="eventForm"
                    (ngSubmit)="checkLogin()"
                    id="loginform"
                  >
                    <div class="form-group">
                      <input
                        placeholder="Email"
                        type="text"
                        class="form-control"
                        #email1
                        id="email1"
                        formControlName="email1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.email1.errors,
                          'form-control-danger': submitted && f.email1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.email1.errors">
                        <p class="error-message loginemail">
                          Email is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        #password1
                        id="password1"
                        formControlName="password1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.password1.errors,
                          'form-control-danger': submitted && f.password1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.password1.errors">
                        <p class="error-message">Password is required!</p>
                      </ng-container>
                    </div>
                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign In " class="signin" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showLoginMsgEmail">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Invalid Email !!
                      </p>
                    </div>
                    <div class="row" *ngIf="showLoginMsgPwd">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Incorrect Password !!
                      </p>
                    </div>
                    <div class="rgister">
                      Don’t have an account?
                      <a
                        href="javascript:void(0)"
                        (click)="openModal('up-modal')"
                        >Register Now</a
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>
<jw-modal class="first_modal in" id="up-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('up-modal')">
          <img src=" ../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <div class="col-md-12">
                  <form
                    [formGroup]="eventForm1"
                    #signupform
                    [formGroup]="eventForm1"
                    (ngSubmit)="addSignup()"
                  >
                    <!-- <div class="form-group file_type">
									 <img src="../../assets/images/up.png" > 
                                     <input placeholder="profilepic" type="file" class="form-control" nbInput fullWidth #file accept='image/*' id="image" name="image" (change)="preview(file.files)">
                                     <input type="hidden" formControlName="image" class="form-control" />
                                    </div> -->
                    <div class="form-group file_type">
                      <img
                        [src]="imageSrc"
                        (error)="onImgError($event)"
                        style="border-radius: 50%"
                      />
                      <input
                        placeholder="profilepic"
                        type="file"
                        class="form-control"
                        fullWidth
                        #file
                        accept="image/*"
                        id="image"
                        name="image"
                        (change)="preview($event)"
                      />
                    </div>
                    <!-- <div>
                                                <figure class="image is-128x128">
                                                  <img [src]="imageSrc"/>
                                                </figure>
                                              </div> -->
                    <div class="form-group">
                      <img src="../../assets/images/user.png" />
                      <input
                        placeholder="Username"
                        type="text"
                        class="form-control"
                        #username
                        id="username"
                        formControlName="username"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.username.errors,
                          'form-control-danger':
                            submitted1 && f1.username.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.username.errors">
                        <p class="error-message">Username is required!</p>
                      </ng-container>
                    </div>
                    <!-- <div class="form-group">
                                                  <img src="images/user.png" > 
                                                             <input placeholder="Username" type="email" class="form-control" id="email1" name="email" required="">
                                                          </div> -->
                    <div class="form-group">
                      <select
                        class="form-control"
                        (change)="titleSelected($event)"
                        style="border: '3px solid #b4b2b2 '"
                        #title
                        id="title"
                        formControlName="title"
                        required=""
                        [(ngModel)]="nrSelect"
                      >
                        <option [ngValue]="0">Attorney</option>
                        <option [ngValue]="1">Associate</option>
                        <option [ngValue]="2">Legal Assistant</option>
                        <option [ngValue]="3">Office manager</option>
                        <option [ngValue]="4">Paralegal</option>
                      </select>
                      <!-- <div *ngIf="f.website.touched && f.website.invalid" class="alert alert-danger">
                        <div *ngIf="f.website.errors.required">Name is required.</div>
                      </div> -->
                    </div>
                    <div class="form-group">
                      <select
                        class="form-control"
                        (change)="languageSelected($event)"
                        style="border: '3px solid #b4b2b2 '"
                        #language
                        id="language"
                        formControlName="language"
                        required=""
                        [(ngModel)]="nrSelect2"
                      >
                        <option [ngValue]="0">English</option>
                        <option [ngValue]="1">Arabic</option>
                        <option [ngValue]="2">Chinese</option>
                        <option [ngValue]="3">French</option>
                        <option [ngValue]="4">German</option>
                        <option [ngValue]="5">Hindi</option>
                        <option [ngValue]="6">Korean</option>
                        <option [ngValue]="7">Polish</option>
                        <option [ngValue]="8">Russian</option>
                        <option [ngValue]="9">Spanish</option>
                        <option [ngValue]="10">Tagalog</option>
                        <option [ngValue]="11">Farsi</option>
                        <option [ngValue]="12">Italian</option>
                        <option [ngValue]="13">Japanese</option>
                        <option [ngValue]="14">Portuguese</option>
                        <option [ngValue]="15">Vietnamese</option>
                      </select>
                      <!-- <div *ngIf="f.website.touched && f.website.invalid" class="alert alert-danger">
                                              <div *ngIf="f.website.errors.required">Name is required.</div>
                                            </div> -->
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/call.png" />
                      <input
                        placeholder="+1 Phone Number"
                        type="phone"
                        class="form-control"
                        #phone
                        id="phone"
                        formControlName="phone"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.phone.errors,
                          'form-control-danger': submitted1 && f1.phone.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.phone.errors">
                        <p
                          class="error-message"
                          *ngIf="submitted1 && f1.phone.errors.required"
                        >
                          Phone number is required !
                        </p>
                        <p
                          class="error-message"
                          *ngIf="f1.phone.errors.pattern"
                        >
                          Valid Phone number is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/location.png" />
                      <input
                        placeholder="Location"
                        type="text"
                        class="form-control"
                        #location
                        id="location"
                        formControlName="location"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.location.errors,
                          'form-control-danger':
                            submitted1 && f1.location.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.location.errors">
                        <p class="error-message">Location is required!</p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/email.png" />
                      <input
                        placeholder="Email"
                        type="email"
                        class="form-control"
                        id="email"
                        #email
                        formControlName="email"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.email.errors,
                          'form-control-danger': submitted1 && f1.email.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.email.errors">
                        <p
                          class="error-message"
                          *ngIf="submitted1 && f1.email.errors.required"
                        >
                          Please enter the Email !
                        </p>
                        <p
                          class="error-message"
                          *ngIf="f1.email.errors.pattern"
                        >
                          Valid Email is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        #password
                        id="password"
                        formControlName="password"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.password.errors,
                          'form-control-danger':
                            submitted1 && f1.password.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.password.errors">
                        <p
                          class="error-message"
                          *ngIf="submitted1 && f1.password.errors.required"
                        >
                          Password is required !
                        </p>
                        <p
                          class="error-message"
                          *ngIf="
                            f1.password.value != '' &&
                            f1.password.value.length < 6
                          "
                        >
                          Minimum 6 characters Password is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder=" Confirm Password"
                        type="password"
                        class="form-control"
                        #confirmpassword
                        id="confirmpassword"
                        formControlName="confirmpassword"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.confirmpassword.errors,
                          'form-control-danger':
                            submitted1 && f1.confirmpassword.errors
                        }"
                      />
                      <ng-container
                        *ngIf="submitted1 && f1.confirmpassword.errors"
                      >
                        <p
                          class="error-message"
                          *ngIf="
                            submitted1 && f1.confirmpassword.errors.required
                          "
                        >
                          Confirm password is required !
                        </p>
                        <p
                          class="error-message"
                          *ngIf="
                            f1.confirmpassword.errors.passwordNotMatch &&
                            f1.confirmpassword.value != ''
                          "
                        >
                          Confirm password is not match with password!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group terms">
                      <label for="other" style="margin-top: 3px"
                        ><a
                          href="../../assets/docs/terms_and_conditions.pdf"
                          target="_blank"
                          >I accept terms & conditions</a
                        ></label
                      >
                      <input
                        type="radio"
                        formControlName="other"
                        id="other"
                        value="other"
                        [ngClass]="{
                          'is-invalid': submitted1 && f1.other.errors,
                          'form-control-danger': submitted1 && f1.other.errors
                        }"
                      />
                      <ng-container *ngIf="submitted1 && f1.other.errors">
                        <p class="error-message">
                          Please accept the terms and conditions !
                        </p>
                      </ng-container>
                    </div>
                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign Up " id="signup" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showMsg">
                      <p class="alert alert-danger" style="margin-top: 10px">
                        <strong>Signup Failed</strong> {{ errmsg }}
                      </p>
                    </div>
                    <div class="rgister">
                      Already a member?
                      <a
                        href="javascript:void(0)"
                        (click)="openModal('login-modal')"
                        >Sign in
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>
<header class="" id="navbar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/"
            ><img src="../../assets/images/logo.png"
          /></a>
          <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navb"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="navbar-toggler-icon click_class_agian"></span>
          </button>
          <div class="collapse navbar-collapse" id="navb">
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <!-- <form class="form-inline my-2 my-lg-0 srch_bar up">
                           <input class="form-control mr-sm-2" type="text" id="searchText" placeholder="Search...">
                           <button class="btn btn-success my-2 my-sm-0" id="srchBtn" type="button" (click)="findText()"><i aria-hidden="true" class="fa fa-search"></i></button>
                        </form> -->
            <ul class="navbar-nav ul_second up" *ngIf="lawFirmEmail == null">
              <li class="nav-item">
                <a class="nav-link" (click)="openModal('login-modal')"
                  >Sign In</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" (click)="openModal('up-modal')">Sign Up</a>
              </li>
            </ul>
            <ul class="navbar-nav ul_second up" *ngIf="lawFirmEmail != null">
              <li
                class="nav-item profiles"
                *ngIf="lawPicType != ''"
                style="margin-top: 40px"
              >
                <a class="nav-link"
                  ><img
                    src="{{ lawPic }}"
                    height="50"
                    width="50"
                    style="border-radius: 50%"
                /></a>
              </li>
              <li
                class="nav-item profiles"
                *ngIf="lawPicType == ''"
                style="margin-top: 40px"
              >
                <a class="nav-link"
                  ><img
                    src="../../assets/images/userr.png"
                    height="50"
                    width="50"
                    style="border-radius: 50%"
                /></a>
              </li>
              <li class="nav-item" style="margin-top: 45px">
                <a class="nav-link" (click)="signOut()">Sign Out</a>
              </li>
            </ul>
          </div>
          <div class="mobile_menu mobile_navbar" id="navb_mobile">
            <a class="close_me"> <img src="../../assets/images/close.png" /></a>
            <!-- <form class="form-inline my-2 my-lg-0 srch_bar up">
                           <input class="form-control mr-sm-2" type="text" name="term" placeholder="Search..." [(ngModel)]="term">
                           <button class="btn btn-success my-2 my-sm-0" type="button"><i aria-hidden="true" class="fa fa-search"></i></button>
                        </form> -->
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <ul class="navbar-nav log_in up">
              <li class="nav-item" *ngIf="lawFirmEmail == null">
                <a class="nav-link" (click)="openModal('login-modal')"
                  >Sign In</a
                >
              </li>
              <li class="nav-item" *ngIf="lawFirmEmail == null">
                <a class="nav-link" (click)="openModal('up-modal')">Sign Up</a>
              </li>
              <li class="nav-item" *ngIf="lawFirmEmail != null">
                <a class="nav-link" (click)="signOut()"
                  ><img class="log_out" src="../../assets/images/logout.png" />
                  Log Out</a
                >
              </li>
              <li class="nav-item">
                <p class="soci">
                  <a href="https://www.facebook.com/maxc1936" target="_blank">
                    <img src="../../assets/images/f.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f2.png"> </a> -->
                  <a
                    href="https://www.youtube.com/user/0426Max/videos"
                    target="_blank"
                  >
                    <img src="../../assets/images/f3.png" />
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<section class="baner">
  <img src="../../assets/images/Banner.webp" />
  <!-- <img src="../../assets/images/1.png" /> -->
</section>
<section class="how_it_works">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="how_it">
          <h3 class="title">how does it work?</h3>
          <p class="title_p">
            Nauatili offers law firms a user-friendly platform to promote their
            services and generate more leads.
          </p>
          <p class="title_p">
            With support for 11 languages and 20 legal practices, Nauatili
            connects firms with clients seeking tailored legal representation,
            enhancing visibility and client engagement.
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="how_it_4">
          <h3 class="">Be visible</h3>
          <p class="">
            <!-- Showcase your Professional profile on Nauatili and gain access to the Latino communities -->
            <!-- Showcase your professional profile on Nauatili and gain access to
            the Latino communities. intial -->
            <!-- Showcase your professional profile. -->
            <!-- Never miss a call or chat request with app notifications -->
            Showcase your credentials and professional profile
          </p>
          <p class="mt-3">Engage your audience with 30-second soundbites</p>
        </div>
        <div class="how_it_4">
          <h3 class="">Be credible</h3>
          <p class="">
            <!-- Give consumers a clear reason to choose your Firm by highlighting your experience and longevity in the Industry -->
            <!-- Give consumers a clear reason to choose your firm by highlighting
            your experience and longevity in the industry.  initial-->
            <!-- Attract more clients with a compelling description of your
            experience and longevity in the industry. -->
            <!-- Maximize exposure with 30-mile search protection -->
            Win more client business with a compelling description of your
            experience and longevity in the industry
          </p>
          <p class="mt-3">Showcase awards and recognitions</p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="how_it__img">
          <img src="../../assets/images/how.png" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="how_it_4 mb-5">
          <h3 class="">Increase Leads</h3>
          <p class="">
            <!-- Offer real-time customer service with Live Chat 24/7 initial-->
            <!-- Provide real-time client support with live-chat 24/7 -->
            Select from 20 Practices
          </p>
          <p class="mt-3">English and 10 other languages are available</p>
          <p class="mt-3">
            Select up to 4 Practices, and Nauatili will showcased your law firm
            on that particular practice search.
          </p>
        </div>
        <div class="how_it_4">
          <h3 class="">Accelerate Growth</h3>
          <p class="">
            <!-- Generate more business with on-spot video consultations, engage with
            future customers with the latest legal news by utilizing soundbites. initial-->
            <!-- Double conversions with video consultations -->
            Never miss a prospects call or chat request with app notifications
          </p>
          <p class="mt-3">30-mile search protection Statewide searches</p>
          <p class="mt-3">International searches (Immigration Esq)</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="use_app">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-5">
        <div class="use_app_screen">
          <img src="../../assets/images/app_1.png" />
        </div>
      </div>
      <div class="col-md-5">
        <div class="use_app_text">
          <h3></h3>
          <a
            href="https://apps.apple.com/us/app/nauatili/id1549521489"
            target="_blank"
          >
            <strong>Use App</strong></a
          >
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how_it_works">
  <div class="container">
    <div class="row">
      <div class="col-md-12 mb-4">
        <div class="how_it">
          <h3 class="title">About us</h3>
        </div>
      </div>
      <div class="col-md-6">
        <div class="about">
          <p class="">
            As the U.S. becomes increasingly diverse, with millions speaking
            languages other than English, Nauatili addresses this shift by
            offering a multilingual platform. It connects users with law firms
            that cater to their language needs, ensuring better access to legal
            services for all.
          </p>
          <br />
        </div>
      </div>
      <div class="col-md-6">
        <div class="how_it__img">
          <!-- <img src="../../assets/images/logo3.jpg" /> -->
          <video
            controls
            controlsList="nodownload"
            poster="../../assets/images/thumbnail.png"
            style="width: 100%"
          >
            <source src="../../assets/images/website.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="how_it_works">
  <div class="how_it">
    <h3 class="title">App Screenshots</h3>
  </div>
  <div class="screen_shot">
    <img src="../../assets/images/screen.png" />
  </div>
</section>
<section class="banner_3">
  <div class="container">
    <div class="row align-items-center h-100">
      <div class="col-md-6 offset-md-3">
        <div class="main_img2">
          <h2>NAUATILI LLC</h2>
          <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.  <br> Lorem Ipsum has been the industry's standar</p> -->
          <div class="banner_serch">
            <a
              href="https://play.google.com/store/apps/details?id=com.nauatili"
              target="_blank"
            >
              <img src="../../assets/images/play1.png"
            /></a>
            <a
              href="https://apps.apple.com/us/app/nauatili/id1549521489"
              target="_blank"
            >
              <img src="../../assets/images/play2.png"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="contact_us">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="how_it">
          <h3 class="title">contact us</h3>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6 contact offset-md-1">
        <!-- <p>For customer support please send us an email and will get back to you ASAP.<br>Thank you,</p> -->
        <!-- <p class="contactBullets"> -->
        <!-- * Introductory monthly subscription $9.95  initial-->
        <!-- - Sign up for $1 per month -->
        <!-- </p> -->
        <!-- <p class="contactBullets"> -->
        <!-- * No long term contract or Auto payments pay as you go initial-->
        <!-- - No automatic payments -->
        <!-- </p> -->
        <!-- <p class="contactBullets"> -->
        <!-- * Receive message notifications from future clients initial -->
        <!-- - Pay as you go -->
        <!-- </p> -->
        <!-- <p class="contactBullets">
          * Highlight your experience in both Spanish and English with 30 second
          soundbites
        </p>
        <p class="contactBullets">* Choose from 18 different Law Practices</p> -->
        <div class="foot-about mt-5">
          <h4>Contact Info</h4>
          <p>Nauatili LLC</p>
          <p>Chicago IL 60609</p>
          <p class="mt-3"><b> Email: </b>info@nauatili.com</p>
          <p class="mt-1"><b> Phone: </b>(800) 738-7961</p>
          <p class="mt-1"><b> Fax: </b>(800) 738-7961</p>
        </div>
      </div>
      <div class="col-md-5 contact-form">
        <img src="../../assets/images/contact-img.png" />
        <form
          [formGroup]="eventForm2"
          #requestForm
          [formGroup]="eventForm2"
          (ngSubmit)="addRequest()"
        >
          <div class="form-group">
            <input
              type="email"
              class="form-control input-chng"
              id="requestEmail"
              #requestEmail
              formControlName="requestEmail"
              aria-describedby="emailHelp"
              placeholder="Email"
              [ngClass]="{
                'is-invalid': submitted2 && f2.requestEmail.errors,
                'form-control-danger': submitted2 && f2.requestEmail.errors
              }"
            />
            <ng-container *ngIf="submitted2 && f2.requestEmail.errors">
              <p class="error-message" *ngIf="f2.requestEmail.errors.required">
                Email is required!
              </p>
              <p class="error-message" *ngIf="f2.requestEmail.errors.pattern">
                Valid Email is required!
              </p>
            </ng-container>
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-control input-chng"
              id="requestSubject"
              #requestSubject
              formControlName="requestSubject"
              placeholder="Subject"
              [ngClass]="{
                'is-invalid': submitted2 && f2.requestSubject.errors,
                'form-control-danger': submitted2 && f2.requestSubject.errors
              }"
            />
            <ng-container *ngIf="submitted2 && f2.requestSubject.errors">
              <p class="error-message">Subject is required!</p>
            </ng-container>
          </div>
          <div class="form-group">
            <textarea
              class="form-control input-chng"
              rows="3"
              id="requestComment"
              #requestComment
              formControlName="requestComment"
              placeholder="Message"
            ></textarea>
            <ng-container *ngIf="submitted2 && f2.requestComment.errors">
              <p class="error-message">Comment is required!</p>
            </ng-container>
          </div>
          <button type="submit" class="btn rqst">Send Request</button>
          <div class="row" *ngIf="emailSent == 'true'">
            <p
              class="alert alert-danger"
              style="margin: 0 auto; margin-top: 20px"
            >
              <strong>Email Sent !!</strong>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="foot-about">
          <h4>Contact Info</h4>
          <p>Nauatili LLC</p>
          <p>Chicago IL 60609</p>
          <p class="mt-3"><b> Email: </b>info@nauatili.com</p>
          <p class="mt-1"><b> Phone: </b>(800) 738-7961</p>
          <p class="mt-1"><b> Fax: </b>(800) 738-7961</p>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Follow Us</h4>
          <ul class="">
            <li>
              <a href="https://www.facebook.com/maxc1936" target="_blank">
                <img src="../../assets/images/f.png" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nauatili-llc"
                target="_blank"
              >
                <img src="../../assets/images/f2.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.linkedin.com" target="_blank"> <img src="../../assets/images/f2.png"> </a> </li> -->
            <li>
              <a
                href="https://www.youtube.com/user/0426Max/videos"
                target="_blank"
              >
                <img src="../../assets/images/f3.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.plus.google.com" target="_blank"> <img src="../../assets/images/f4.png"> </a> </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Payment Method</h4>
          <ul class="">
            <li>
              <a href="javascript:void(0)">
                <img src="../../assets/images/paypal.jpg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</footer>
<h5 class="cap">2020 © copyright all right reserved</h5>
<script src="https://kit.fontawesome.com/2b7d98f67f.js"></script>
<script src="../../assets/js/Jquery.min.js"></script>
<script src="../../assets/js/owl.carousel.js"></script>
<script src="../../assets/js/aos.js"></script>
<script src="../../assets/js/custom.js"></script>
<script src="../../assets/js/bootstrap.js"></script>
<!-- </body>
</html> -->
