import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from '../../environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, NgForm } from '@angular/forms';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import {ModalService} from '../_modal';
declare var $ : any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  lawPic = "";
  lawfirm : any;
  lawFirmEmail = '';
  lawFirmName = '';
  lawPicType = '';
  lawFirmPhone = '';
  lawFirmLocation = '';
  expiry:any;
  lawExpiry = '';
  imageSrc:string | ArrayBuffer;
  submitted = false;
submitted1 = false;
showLoginMsgEmail = false;
showLoginMsgPwd = false;
eventForm: FormGroup;
eventForm1: FormGroup;
showMsg = false;
fd = new FormData();
selectedFile: File = null;
@ViewChild('email1') email1: ElementRef;
  @ViewChild('password1') password1: ElementRef;
  @ViewChild('username') username: ElementRef;
  @ViewChild('phone') phone: ElementRef;
  @ViewChild('location') location: ElementRef;
  @ViewChild('email') email: ElementRef;
  @ViewChild('password') password: ElementRef;
  @ViewChild('confirmpassword') confirmpassword: ElementRef;
  baseUrl = environment.baseUrl;
    userImagesUrl = environment.userImagesUrl;
  passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
        this.eventForm1 &&
        (control.value !== this.eventForm1.controls.password.value)
    ) {
        return { passwordNotMatch: true };
    }
    return null;
}
  constructor(private router: Router, private http: Http, public modalService: ModalService,public formBuilder: FormBuilder,public formBuilder1: FormBuilder) { }

  ngOnInit(): void {
    $(document).ready(function($) {
      $(".one").focus(function(){
      $(this).parent().parent().addClass("round");
      })
      
      }); 

      $(document).ready(function () {
//  $("nav").find("li").on("click", ".close_me", function () {
   $(".close_me").click(function () {
      $("#navb_mobile").addClass("hide_div");

 });
 $(".click_class_agian").click(function () {
   $("#navb_mobile").removeClass("hide_div");
      $("#navb_mobile").addClass("hide_divs");

 });
 
});
    this.lawPic = this.userImagesUrl + localStorage.getItem('lawPic');
    if( localStorage.getItem('lawPic') == "/images/" ){
      this.lawPicType = "";
    } else {
      this.lawPicType = "1";
    }
    this.lawFirmEmail = localStorage.getItem('lawEmail');
    this.lawFirmName = localStorage.getItem('lawName');
    this.lawFirmPhone = localStorage.getItem('lawPhone');
    this.lawFirmLocation = localStorage.getItem('lawLocation');
    const emailPattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
      this.eventForm = this.formBuilder.group({
        email1: ['',[Validators.required,Validators.pattern(emailPattern)]],
        password1: ['',[Validators.required]]
       });


       this.eventForm1 = this.formBuilder1.group({
        username: ['',[Validators.required]],
        phone: ['',[Validators.required, Validators.pattern("[0-9 ]{10}")]],
        location: ['',[Validators.required]],
        email: ['',[Validators.required,Validators.pattern(emailPattern)]],
        password: ['',[Validators.required, Validators.minLength(6)]],
        confirmpassword: ['',[Validators.required, this.passwordMatcher.bind(this)]],
        other: ['',[Validators.required]]
       });
       var isLoggedOut = localStorage.getItem('isLoggedOut');
       if( isLoggedOut == 'true' ){
        //window.location.replace('/');
        this.router.navigate(['/']);
      } 
    this.getExpiry();
  }

  closeModal(id: string) {
    this.modalService.close(id);
    // if( localStorage.getItem('cnt') == '0' ){
    //   this.modalService.close(id);
    // }
    // localStorage.setItem('cnt','0');
  }

  openModal(id: string) {
    if(id == 'login-modal'){
      this.email1.nativeElement.value = '';
      this.password1.nativeElement.value = '';
      this.submitted = false;
      this.modalService.close('up-modal');
    } else {
      this.imageSrc = '';
      this.username.nativeElement.value = '';
      this.phone.nativeElement.value = '';
      this.location.nativeElement.value = '';
      this.email.nativeElement.value = '';
      this.password.nativeElement.value = '';
      this.confirmpassword.nativeElement.value = '';
      this.submitted1 = false;
      this.modalService.close('login-modal');
    }
    this.modalService.open(id);
    localStorage.setItem('cnt','1');
}

get f() { return this.eventForm.controls; }
get f1() { return this.eventForm1.controls; }

onImgError(event){
  event.target.src = '../../assets/images/up.png';
}

checkLogin(){
  this.submitted = true;
  if (this.eventForm.invalid) {
    return;
  }
  this.http.post(this.baseUrl+'login', {email: this.f.email1.value, password: this.f.password1.value}).subscribe(
    (response: any) => {
      var lawfirm = JSON.parse(response._body);
      localStorage.setItem('lawfirm', lawfirm);
      localStorage.setItem('lawPic', lawfirm.result.profileImage)
      localStorage.setItem('lawEmail', lawfirm.result.email)
      localStorage.setItem('lawName', lawfirm.result.username)
      localStorage.setItem('lawPhone', lawfirm.result.phone)
      localStorage.setItem('lawLocation', lawfirm.result.location)
      localStorage.setItem('purp', '1');
      localStorage.setItem('isLoggedOut', 'false');
      this.router.navigate(['/index']); 
    },
    (error) => {
      var lawFirmErr = JSON.parse(error._body);
      if( lawFirmErr.message == "Email not exists !!" ){
        this.showLoginMsgEmail = true;
        setTimeout(() => {
          this.showLoginMsgEmail = false;
        },3000)
      } else if( lawFirmErr.message == "Invalid Password !!" ){
        this.showLoginMsgPwd = true;
        setTimeout(() => {
          this.showLoginMsgPwd = false;
        },3000)
      }
    }
  )
}

addSignup(){
  this.submitted1 = true;
  if (this.eventForm1.invalid) {
    return;
  }
  
  this.fd.set('username',this.f1.username.value);
  this.fd.set('phone',this.f1.phone.value);
  this.fd.set('location',this.f1.location.value);
  this.fd.set('email',this.f1.email.value);
  this.fd.set('password',this.f1.password.value);
  //this.formData.append('image',this.fileData);
  //formData['username'] = this.f1.username.value;
  //formData['phone'] = this.f1.phone.value;
  //formData['location'] = this.f1.location.value;
  //formData['email'] = this.f1.email.value;
  //formData['password'] = this.f1.password.value;
  this.http.post(this.baseUrl+'signup', this.fd).subscribe(
    (response: any) => {
      console.log("ENTER---")
      console.log(this.fd);
      var lawfirm = JSON.parse(response._body);
      if( lawfirm.message == "Exists" ){
        this.showMsg = true;
      setTimeout(() => {
        this.showMsg = false;
      },3000)
      } else{
        localStorage.setItem('lawfirm', lawfirm);
      console.log("html lawfirm---",lawfirm.result)
      localStorage.setItem('lawPic', lawfirm.result.profileImage)
      localStorage.setItem('lawEmail', lawfirm.result.email)
      localStorage.setItem('lawName', lawfirm.result.username)
      localStorage.setItem('lawPhone', lawfirm.result.phone)
      localStorage.setItem('lawLocation', lawfirm.result.location)
      localStorage.setItem('purp', '0');
      localStorage.setItem('isLoggedOut', 'false');
       this.router.navigate(['/step2']);  
      } 
    },
    (error) => {
      
    }
  )
}

preview(event) {
  this.selectedFile = <File>event.target.files[0];
  const reader = new FileReader();
        reader.onload = e => this.imageSrc = reader.result;
        reader.readAsDataURL(this.selectedFile);
  this.fd.append('image', this.selectedFile, this.selectedFile.name);
}

  getExpiry(){
    var obj = {
      lawphone : this.lawFirmPhone
    }
    this.http.post(this.baseUrl+'getexpiry', obj).subscribe(
      (response: any) => {
        console.log('----------->',response);
        var exp = JSON.parse(response._body);
        console.log(exp)
        if( exp.isExpiry == 0 ){
          this.expiry = 0
          this.lawExpiry = exp.result;
        } else {
          this.expiry = 1
          this.lawExpiry = exp.result;
        }
        if(exp.result == ''){
          this.lawExpiry = ''
        }
      },
      (error) => {

      } 
    );
  }

  buyIt(){
    this.router.navigate(['/step2']);
  }
  signOut(){
    this.http.get(this.baseUrl+'logout').subscribe(
      (response: any) => {
        if(confirm("Are you sure want to logout ?")){
          console.log(response);
          // localStorage.removeItem('lawfirm');
          // localStorage.removeItem('lawPic');
          // localStorage.removeItem('purp');
          // localStorage.setItem('isLoggedOut', 'true');
          localStorage.removeItem('lawfirm');
        localStorage.removeItem('lawPic');
        localStorage.removeItem('purp');
        localStorage.removeItem('lawEmail');
        localStorage.removeItem('lawName');
        localStorage.removeItem('lawPhone');
        localStorage.removeItem('lawLocation');
        localStorage.setItem('isLoggedOut', 'true');
          this.router.navigate(['/']); 
        }
      },
      (error) => {

      } 
    );
  }

}
