import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from './_modal';
import { HttpModule } from '@angular/http';
import { AboutComponent } from './about/about.component';
import { ContactusComponent } from './contactus/contactus.component';
import { Signupstep2Component } from './signupstep2/signupstep2.component';
import { Signupstep3Component } from './signupstep3/signupstep3.component';
import { Signupstep4Component } from './signupstep4/signupstep4.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Home2Component } from './home2/home2.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PracticesComponent } from './practices/practices.component';
//import { WindowRefService } from './WindowRef.service';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactusComponent,
    Signupstep2Component,
    Signupstep3Component,
    Signupstep4Component,
    Home2Component,
    DashboardComponent,
    PracticesComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ModalModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
