<jw-modal class="first_modal in" id="login-modal">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-body">
        <div type="button" class="close-1" (click)="closeModal('login-modal')">
          <img src="../../assets/images/cross.png" />
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <div class="log-in_form">
                <img src="../../assets/images/logo.png" class="modal-img" />
                <h3 class="">
                  Sign in to your account <br />
                  to continue!
                </h3>
                <div class="col-md-12">
                  <form
                    [formGroup]="eventForm"
                    #loginform
                    [formGroup]="eventForm"
                    (ngSubmit)="checkLogin()"
                    id="loginform"
                  >
                    <div class="form-group">
                      <input
                        placeholder="Username"
                        type="text"
                        class="form-control"
                        #email1
                        id="email1"
                        formControlName="email1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.email1.errors,
                          'form-control-danger': submitted && f.email1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.email1.errors">
                        <p class="error-message loginemail">
                          Email is required!
                        </p>
                      </ng-container>
                    </div>
                    <div class="form-group">
                      <img src="../../assets/images/key.png" />
                      <input
                        placeholder="Password"
                        type="password"
                        class="form-control"
                        #password1
                        id="password1"
                        formControlName="password1"
                        required=""
                        [ngClass]="{
                          'is-invalid': submitted && f.password1.errors,
                          'form-control-danger': submitted && f.password1.errors
                        }"
                      />
                      <ng-container *ngIf="submitted && f.password1.errors">
                        <p class="error-message">Password is required!</p>
                      </ng-container>
                    </div>
                    <div class="paddin_zero">
                      <div class="log_button">
                        <input type="submit" value="Sign In " class="signin" />
                      </div>
                    </div>
                    <div class="row" *ngIf="showLoginMsgEmail">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Invalid Username !!
                      </p>
                    </div>
                    <div class="row" *ngIf="showLoginMsgPwd">
                      <p
                        class="alert alert-danger"
                        style="margin: 0 auto; margin-top: 20px"
                      >
                        <strong>Signin Failed</strong> Incorrect Password !!
                      </p>
                    </div>
                    <div class="rgister">
                      Don’t have an account?
                      <a
                        href="javascript:void(0)"
                        (click)="openModal('up-modal')"
                        >Register Now</a
                      >
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-6"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</jw-modal>
<header class="" id="navbar">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-expand-lg">
          <a class="navbar-brand" href="/"
            ><img src="../../assets/images/logo.png"
          /></a>
          <button
            class="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#navb"
          >
            <span class="navbar-toggler-icon click_class_agian"></span>
          </button>
          <div class="collapse navbar-collapse" id="navb">
            <ul class="navbar-nav ul_second ml-auto mt-3">
              <li class="nav-item profiles" *ngIf="lawPicType != ''">
                <a class="nav-link"
                  ><img
                    [src]="lawPic"
                    height="50"
                    width="50"
                    style="border-radius: 50%"
                /></a>
              </li>
              <li class="nav-item profiles" *ngIf="lawPicType == ''">
                <a class="nav-link"
                  ><img
                    src="../../assets/images/userr.png"
                    height="50"
                    width="50"
                    style="border-radius: 50%"
                /></a>
              </li>
              <li class="nav-item" style="margin-top: 6px">
                <a class="nav-link" (click)="signOut()">Sign Out</a>
              </li>
            </ul>
          </div>

          <div class="mobile_menu mobile_navbar" id="navb_mobile">
            <a class="close_me"> <img src="../../assets/images/close.png" /></a>
            <!-- <form class="form-inline my-2 my-lg-0 srch_bar up">
                           <input class="form-control mr-sm-2" type="text" placeholder="Search...">
                           <button class="btn btn-success my-2 my-sm-0" type="button"><i aria-hidden="true" class="fa fa-search"></i></button>
                        </form> -->
            <ul class="navbar-nav mr-auto ul_first">
              <li class="nav-item">
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/about"> About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/practices">Practices</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/privacy">Privacy Policy</a>
              </li>
            </ul>
            <ul class="navbar-nav log_in up">
              <!-- <li class="nav-item">
                              <a class="nav-link" href="javascript:void(0)" (click)="openModal('login-modal')">Sign In</a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link" href="javascript:void(0)" (click)="openModal('up-modal')">Sign Up</a>
                           </li> -->
              <!-- <li class="nav-item">
                              <a class="nav-link" (click)="signOut()"><img class="log_out" src="../../assets/images/logout.png" > Log Out</a>
                           </li> -->
              <li class="nav-item" *ngIf="lawFirmEmail != null">
                <a class="nav-link" (click)="signOut()"
                  ><img class="log_out" src="../../assets/images/logout.png" />
                  Log Out</a
                >
              </li>
              <li class="nav-item">
                <p class="soci">
                  <a href="https://www.facebook.com/maxc1936" target="_blank">
                    <img src="../../assets/images/f.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f2.png"> </a> -->
                  <a
                    href="https://www.youtube.com/user/0426Max/videos"
                    target="_blank"
                  >
                    <img src="../../assets/images/f3.png" />
                  </a>
                  <!-- <a href="#"> <img src="../../assets/images/f4.png"> </a> -->
                </p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<section class="baner">
  <img src="../../assets/images/use_app.png" />
</section>

<div class="stpes_profile">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="use_app2">
          <p><img src="../../assets/images/call.png" />{{ lawFirmPhone }}</p>
          <p>
            <img src="../../assets/images/location.png" /> {{ lawFirmLocation }}
          </p>
        </div>
      </div>
      <div class="col-md-4">
        <div class="profile_photo">
          <img
            [src]="lawPic"
            height="210"
            width="210"
            style="border-radius: 50%; object-fit: cover"
            *ngIf="lawPicType != ''"
          />
          <img
            src="../../assets/images/userr.png"
            height="210"
            width="210"
            style="border-radius: 50%"
            *ngIf="lawPicType == ''"
          />
          <h3>{{ lawFirmName }}</h3>
          <h4>{{ lawFirmEmail }}</h4>

          <a
            href="https://apps.apple.com/us/app/nauatili/id1549521489"
            target="_blank"
          >
            Go to App</a
          >
        </div>
      </div>
      <div class="col-md-4">
        <div class="use_app3">
          <p>Subscription Detail</p>
          <h3
            class="mb-3"
            style="color: #db0000"
            *ngIf="expiry != 0 && isFound == 1"
          >
            Expired on {{ lawExpiry }}
          </h3>
          <h3 class="mb-3" style="color: #db0000" *ngIf="expiry == 0">
            Valid till {{ lawExpiry }}
          </h3>
          <h3 class="mb-3" style="color: #db0000" *ngIf="lawExpiry == ''">
            Please buy Subscription
          </h3>
          <a href="javascript:void(0)" *ngIf="expiry != 0" (click)="buyIt()"
            ><span class="basic_plan"> Buy Now</span></a
          >
          <a href="javascript:void(0)" *ngIf="expiry == 0" (click)="cancel()"
            ><span class="basic_plan"> Cancel</span></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="foot-about">
          <h4>Contact Info</h4>
          <p>Nauatili LLC</p>
          <p>Chicago IL 60609</p>
          <p class="mt-3"><b> Email: </b>nauatili2020@gmail.com</p>
          <p class="mt-1"><b> Phone: </b>(800) 738-7961</p>
          <p class="mt-1"><b> Fax: </b>(800) 738-7961</p>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Follow Us</h4>
          <ul class="">
            <li>
              <a href="https://www.facebook.com/maxc1936" target="_blank">
                <img src="../../assets/images/f.png" />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/nauatili-llc"
                target="_blank"
              >
                <img src="../../assets/images/f2.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.linkedin.com" target="_blank"> <img src="../../assets/images/f2.png"> </a> </li> -->
            <li>
              <a
                href="https://www.youtube.com/user/0426Max/videos"
                target="_blank"
              >
                <img src="../../assets/images/f3.png" />
              </a>
            </li>
            <!-- <li> <a href="https://www.plus.google.com" target="_blank"> <img src="../../assets/images/f4.png"> </a> </li> -->
          </ul>
        </div>
      </div>
      <div class="col-md-4 ml-5 ml-sm-0">
        <div class="social">
          <h4>Payment Method</h4>
          <ul class="">
            <li>
              <a href="javascript:void(0)">
                <img src="../../assets/images/paypal.jpg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <h5 class="cap">2020 © copyright all right reserved</h5>
</footer>
<script src="../../assets/js/Jquery.min.js"></script>
<script src="../../assets/js/bootstrap.js"></script>
<script src="../../assets/js/owl.carousel.js"></script>
<script src="../../assets/js/aos.js"></script>
<script src="../../assets/js/custom.js"></script>
