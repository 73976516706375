// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:3115/',
  // imagesUrl: 'http://localhost:3115/',
  // userImagesUrl: 'http://localhost:3115/images/users/',
  baseUrl: 'https://nauatili.com/',
  imagesUrl: 'https://nauatili.com/',
  userImagesUrl: 'https://nauatili.com/images/users/',
  googleMapKey: '',
  monthlySub: 9.95,
  listOnePractice: 1,
  listTwoPractice: 2,
  listThreePractice: 3,
  listFourPractice: 4,
  listOnePracticeString: '1',
  listTwoPracticeString: '2',
  listThreePracticeString: '3',
  listFourPracticeString: '4',
  listZeroTitleString: '0',
  listOneTitleString: '1',
  listTwoTitleString: '2',
  listThreeTitleString: '3',
  listFourTitleString: '4',
  English: '0',
  Arabic: '1',
  Chinese: '2',
  French: '3',
  German: '4',
  Hindi: '5',
  Korean: '6',
  Polish: '7',
  Russian: '8',
  Spanish: '9',
  Tagalog: '10',
  Farsi: '11',
  Italian: '12',
  Japanese: '13',
  Portuguese: '14',
  Vietnamese: '15',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
